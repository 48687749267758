import React from 'react';
import formatDate from '../../helpers/dateTime.js';

function Users(props){
    return (
        <div className='app-edit-container'>
            {props.userList && props.userList.map((user, index) => {
                return (
                    <div className='app-admin-user-record' key={index}>
                        <span className='app-admin-user-displayname'>{user.DisplayName}</span>
                        <span className='app-admin-user-username' aria-label={'id: ' + user.UserId} title={'id: ' + user.UserId}>{user.UserName}</span>
                        <span className='app-admin-user-role'>{user.Role}</span>
                        <span className='app-admin-user-lastlogin'>{formatDate(user.LastLogin, true, true)}</span>
                    </div>
                )})
            }
        </div>
    )
}

export default Users;