import React, { useState, useEffect } from 'react';

import consoleBackgroud from '../images/consoleBG.jpg';
import Playground from './components/PlayGround/playGround.js';
import CommandPrompt from './components/Console/commandPrompt.js';


function ConsoleView(props) {
    const [commandText, setCommandText] = useState('');
    const [commandPrompt, setCommandPrompt] = useState('enter command');
    const [commandHistory, setCommandHistory] = useState([]);
    const [activeCommand, setActiveCommand] = useState('');
    const [showPlayArea, setShowPlayArea] = useState(false);
    const [gameId, setGameId] = useState(0);
    const [invalidInputEntry, setInvalidInputEntry] = useState(false);
    const [redirectCommands, setRedirectCommands] = useState(['resume', 'vr']);
    const [secureRedirectCommands, setSecureRedirectCommands] = useState(['admin', 'journal']);
    const [secureCommands, setSecureCommands] = useState(['register']);
    const [user, setUser] = useState({ userName: '', password: '', action: 'login', role: '', displayName: '' });

    const loggedInUser = JSON.parse(window.sessionStorage.getItem('user'));

    useEffect(() => {
        setRedirectCommands(['resume', 'vr']);
        setSecureRedirectCommands(['admin', 'journal']);
        setSecureCommands(['register']);
    }, []);

    const redirectHandler = (redirectTo) => {
        document.location = "/" + redirectTo
    }

    return (
        <div className='app-console-view' style={{ backgroundImage: `url(${consoleBackgroud})` }}>
            <CommandPrompt 
                commandPrompt = {commandPrompt} 
                commandText = {commandText} 
                commandHistory = {commandHistory} 
                activeCommand = {activeCommand}
                setCommandText = {setCommandText}
                setCommandHistory = {setCommandHistory}
                setCommandPrompt = {setCommandPrompt}      
                setInvalidInputEntry = {setInvalidInputEntry}    
                setActiveCommand = {setActiveCommand}    
                redirectCommands = {redirectCommands}
                secureRedirectCommands = {secureRedirectCommands}
                secureCommands = {secureCommands}
                redirectHandler = {redirectHandler} 
                loggedInUser = {loggedInUser}
                user = {user}
                setUser = {setUser}
            /> 
            <div className={showPlayArea ? 'app-console-play-area' : 'app-hidden'}>
                <Playground gameId = {gameId} setGameId={setGameId} setShowPlayArea = {setShowPlayArea} />
            </div>
            <div className={invalidInputEntry ? 'app-console-no-no' : 'app-hidden'}>invalid input</div>
        </div>
    );
}

export default ConsoleView