import { apiUrl } from '../config.js';
import axios from 'axios';

function EditEmployer(payload) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    if (payload.id !== '') {
        axios({
            method: 'post',
            url: apiUrl + 'editEmployer',
            headers: { 'authorization': token, 'Content-Type': 'application/json' },
            data: {
                id: payload.id,
                name: payload.name,
                description: payload.description,
                detailedDescription: payload.detailedDescription,
                locationId: payload.locationId,
                roleId: payload.roleId,
                domainId: payload.domainId,
                startDate: payload.startDate,
                endDate: payload.endDate
            }
        })
        .catch(function(error) {
            console.log(error);
            return 'fail';
        })
        .then(function(response) {
            if (response.statusText === 'OK') return 'success';
            else return 'fail';
        })}
    else {
        console.log('you have passed an empty statement - delete instead')
    }
}

export default EditEmployer;