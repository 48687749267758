import axios from 'axios';
import { apiUrl } from '../config.js';

async function Reset(payload, callback) {
    axios.post(apiUrl + 'resetPassword', {
        userName: payload.userName,
        password: payload.password
      })
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });

}

export default Reset;