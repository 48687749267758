import React from 'react';
import Modal from 'react-modal';

import GetStatements from '../api/Biography/getStatements.js';
import GetRoles from '../api/Role/getRoles.js';
import GetEmployers from '../api/Employer/getEmployers.js';
import GetDomains from '../api/Domain/getDomains.js';
import GetLocations from '../api/Location/getLocations.js';
import GetStatuses from '../api/Status/getStatuses.js';
import GetProjects from '../api/Project/getProjects.js';
import GetJournal from '../api/Journal/getJournal.js';
import GetUsers from '../api/Auth/users.js';

import Biography  from './components/Admin/biography.js';
import Roles from './components/Admin/roles.js';
import Employers from './components/Admin/employers.js';
import Status from './components/Admin/status.js';
import Domains from './components/Admin/domains.js';
import Projects from './components/Admin/projects.js';
import Journal from './components/Admin/journal.js';

import SiteNav from './components/Shared/siteNav.js';
import Loader  from './components/Shared/loader.js';    
import Users from './components/Admin/users.js';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
class AdminView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            showEdit: false,
            adminOptions: [ 'Resume', 'Blog', 'Library', 'Wine', 'Journal', 'Users' ],
            adminOptionSections: [ 
                { adminOption: 'Resume', sections: ['Status', 'Biography', 'Employers', 'Roles', 'Domains', 'Projects', 'Skills', 'Locations', ] },
                { adminOption: 'Blog', sections: ['Posts', 'Tags' ] },
                { adminOption: 'Library', sections: ['Books', 'Authors', 'Genres' ] },
                { adminOption: 'Wine', sections: ['Wines', 'Wineries', 'Regions' ] },
                { adminOption: 'Journal', sections: ['Journal Entries' ] },
                { adminOption: 'Users', sections: ['Users', 'Roles' ] },
            ],
            selectedOption: null,
            selectedSection: null,
            bioModalIsOpen: false,
            journalModalIsOpen: false,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    
    openModal(modalType) {
        switch(modalType) {
            case 'bio':
                this.setState({ bioModalIsOpen: true });
                break;
            case'roles':
                this.setState({ rolesModalIsOpen: true });
                break;
            case 'status':
                this.setState({ statusModalIsOpen: true });
                break;
            case 'employers':
                this.setState({ employersModalIsOpen: true });
                break;
            case 'domains':
                this.setState({ domainsModalIsOpen: true });
                break;
            case 'projects':
                this.setState({ projectsModalIsOpen: true });
                break;
            case 'journal':
                this.setState({ journalModalIsOpen: true });
                break;
            case 'users':
                this.setState({ usersModalIsOpen: true });
                break;
            default:
                break;
        }
    }
    
    afterModalOpen() {
       console.log('modal >> afterOpenModal');
    }
    
    closeModal(modalType) {
        this.setState({ modalIsOpen: false });
        switch(modalType) {
            case 'bio':
                this.setState({ bioModalIsOpen: false });
                break;
            case'roles':
                this.setState({ rolesModalIsOpen: false });
                break;
            case 'status':
                this.setState({ statusModalIsOpen: false });
                break;
            case 'employers':
                this.setState({ employersModalIsOpen: false });
                break;
            case 'domains':
                this.setState({ domainsModalIsOpen: false });
                break;
            case 'projects':
                this.setState({ projectsModalIsOpen: false });  
                break;
            case 'journal':
                this.setState({ journalModalIsOpen: false });
                break;
            case 'users':
                this.setState({ usersModalIsOpen: false });
                break;
            default:
                break;
        }
    }
    
    selectOption(option) {
        let selectedOption = this.state.selectedOption;
        for(let i = 0; i < this.state.adminOptionSections.length; i++) {
            if(this.state.adminOptionSections[i].adminOption === option) {
                selectedOption = this.state.adminOptionSections[i];
                break;
            }
        }

        this.setState({ selectedOption: selectedOption });
    }

    selectSection(section) {
        this.setState({ selectedSection: section }, () => {
            this.renderSelectedSection();
        });

    }

    renderSelectedSection() {
        let selectedSection = this.state.selectedSection;
        console.log(selectedSection);
        let that = this;
        if(selectedSection) {
            switch(selectedSection.optionSection) {
                case 'Status':
                    GetStatuses(function(response) {
                        that.setState({ statuses: response, modalIsOpen: true, openModal: 'status' });
                        that.openModal('status');
                    });
                    break;
                case 'Biography':
                    GetStatements(function(response) {
                        that.setState({ biography: response.data, modalIsOpen: true, openModal: 'bio' });
                        that.openModal('bio');
                    });
                    break;
                case 'Employers':
                    GetEmployers(function(response) {
                        that.setState({ employers: response, modalIsOpen: true, openModal: 'employers' });
                        that.openModal('employers');
                    });
                    break;
                case 'Roles':
                    GetRoles(function(response) { 
                        that.setState({ roles: response, modalIsOpen: true, openModal: 'roles' });
                        that.openModal('roles');
                    });
                    break;
                case 'Domains':
                    GetDomains(function(response) {
                        that.setState({ domains: response, modalIsOpen: true, openModal: 'domains' });
                        that.openModal('domains');
                    });
                    break;
                case 'Projects':
                    GetProjects(function(response) {
                        console.log(response);
                        var projectData = { projects: response.projects, projectRoles: response.roles, projectStatuses: response.statuses, projectEmployers: response.employers };
                        console.log(projectData);
                        that.setState({ projects: projectData, modalIsOpen: true, openModal: 'projects' });
                        that.openModal('projects');
                    })
                    break;
                case 'Skills':
                    break;
                case 'Locations':
                    GetLocations(function(response) {
                        that.setState({ locations: response, modalIsOpen: true, openModal: 'locations' });
                        that.openModal('locations');
                    });
                    break;
                case 'Journal Entries':
                    GetJournal(function(response) {
                        that.setState({ journal: response, modalIsOpen: true, openModal: 'journal' });
                        that.openModal('journal');
                    });
                    break;
                case 'Users':
                    GetUsers(function(response) {
                        console.log(response);
                        that.setState({ users: response, modalIsOpen: true, openModal: 'users' });
                        that.openModal('users');
                    });
                    break;
                default:
                    return null;
            }
        }
        else {
            return null;
        }
    }
    
    componentDidUpdate() {
        //first make sure we have all of the data we need
        if (!this.props.isLoading && !this.state.dataLoaded) {
            this.setState({ 
                dataLoaded: true,
                resume: this.props.resume,
            });
        }
    }

    componentDidMount() {
        let user = JSON.parse(window.sessionStorage.getItem('user'));
        if(user && user.Role === 'Owner') {
          this.setState({ showEdit: true });
        }
    }

    render() {
        return (    
            <div>
                <Loader isLoading={this.props.isLoading} />
                <div className='app-admin-container'>
                    <SiteNav />
                    <div className='app-admin-view'> 
                        <div className='app-admin-view-side-nav'>
                            {this.state.adminOptions && this.state.adminOptions.map((option, index) => {
                                return (
                                    <div className={this.state.selectedOption && this.state.selectedOption.adminOption === option ? 'app-admin-view-side-nav-item-selected': 'app-admin-view-side-nav-item'} key={index} onClick={() => this.selectOption(option)}>
                                        {option}
                                    </div>  
                                )
                            })}
                        </div>
                        {this.state.modalIsOpen && (<button className='app-resume-modal-close-button' onClick={() => this.closeModal(this.state.openModal)}>Close</button>)}

                        <div className='app-admin-view-secondary-nav'>
                            {this.state.selectedOption && this.state.selectedOption.sections.map((optionSection, index) => {
                                return (<div className={this.state.selectedOptionSection && this.state.selectedOptionSection === optionSection ? 'app-admin-view-secondary-nav-item-selected' : 'app-admin-view-secondary-nav-item'} key={index} onClick={() => this.selectSection({optionSection})}>{optionSection}</div>) 
                            })}
                        </div> 
                        <div className='app-admin-view-edit-section'>
                            <div className={this.state.statusModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                    <Modal
                                        isOpen={this.state.statusModalIsOpen}
                                        onAfterOpen={this.afterModalOpen}
                                        onRequestClose={this.closeModal}
                                        style={customStyles}
                                        contentLabel="Julie McFadden Search Status"
                                    >   
                                        
                                        <Status allStatuses={this.state.statuses} />
                                    </Modal>
                            </div>                            
                            <div className={this.state.bioModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                    <Modal
                                        isOpen={this.state.bioModalIsOpen}
                                        onAfterOpen={this.afterModalOpen}
                                        onRequestClose={this.closeModal}
                                        style={customStyles}
                                        contentLabel="Julie McFadden Biography"
                                    >   
                                        
                                        <Biography allStatements={this.state.biography} />
                                    </Modal>
                            </div>
                            <div className={this.state.rolesModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                <Modal
                                    isOpen={this.state.rolesModalIsOpen}
                                    onAfterOpen={this.afterModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Julie McFadden Roles"
                                >   
                                    
                                    <Roles allRoles={this.state.roles} />
                                </Modal>                            
                            </div>
                            <div className={this.state.employersModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                <Modal
                                    isOpen={this.state.employersModalIsOpen}
                                    onAfterOpen={this.afterModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Julie McFadden Employers"
                                >   
                                    
                                    <Employers allEmployers={this.state.employers} />
                                </Modal>                            
                            </div>
                            <div className={this.state.domainsModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                <Modal
                                    isOpen={this.state.domainsModalIsOpen}
                                    onAfterOpen={this.afterModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Julie McFadden Industry Domains"
                                >   
                                    
                                    <Domains allDomains={this.state.domains} />
                                </Modal>                            
                            </div>  
                            <div className={this.state.projectsModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                <Modal
                                    isOpen={this.state.projectsModalIsOpen}
                                    onAfterOpen={this.afterModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Julie McFadden Projects"
                                >   
                                    
                                    <Projects allProjects={this.state.projects} />
                                </Modal>   
                            </div>      
                            <div className={this.state.journalModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                <Modal
                                    isOpen={this.state.journalModalIsOpen}
                                    onAfterOpen={this.afterModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Julie McFadden Journal"
                                >   
                                    
                                    <Journal allEntries={this.state.journal} />
                                </Modal>   
                            </div>                                               
                            <div className={this.state.usersModalIsOpen ? 'app-admin-modal' : 'app-hidden'}>
                                <Modal
                                    isOpen={this.state.usersModalIsOpen}
                                    onAfterOpen={this.afterModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Julie McFadden Users"
                                >   
                                    
                                    <Users userList={this.state.users} />
                                </Modal>   
                            </div>                               
                        </div>
                    </div>
                </div>
        </div>
            
        )
    }
}

export default AdminView;