import React from 'react';
import Chevron from 'react-chevron';
import { FaFloppyDisk, FaTrash, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import AddRole from '../../../api/Role/addRole.js';
import EditRole from '../../../api/Role/editRole.js';
import DeleteRole from '../../../api/Role/deleteRole.js';

class Roles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allRoles: [],
            editedRoles: [],
            editedRoleIds: [],
            deletedRoleIds: [],
            newRoles: [],
            newRoleIds: [],
            originalRoleArray: [],
            showSave: false,
            rolesLoaded: false,
            selectedRole: null,
        };

      }
      
      addRole() {
        let allRoles = this.state.allRoles;
        let newRoles = this.state.newRoles;
        let newRoleIds = this.state.newRoleIds;
        let newRoleId = 0;

        if(newRoleIds.length === 0) {
            for(let i=0; i<allRoles.length; i++) {
                if(allRoles[i].Id > newRoleId) newRoleId = allRoles[i].Id;
            }
        } else {
            for(let i=0; i<newRoleIds.length; i++) {
                if(newRoleIds[i] > newRoleId) newRoleId = newRoleIds[i];
            }
        }
        //increment by one to get the new id
        newRoleId++;
        let newRole = { Id: newRoleId, Title: '', Description: '' };
        newRoles.push(newRole);
        newRoleIds.push(newRoleId);

        //update the full list with this new one so it can be edited
        allRoles.push(newRole);

        //set the selected role to the new one for editing and update the state
        this.setState({ allRoles: allRoles, newRoles: newRoles, newRoleIds: newRoleIds, selectedRole: newRole });
      }

      deleteRole(roleId) {
        let deletedRoleIds = this.state.deletedRoleIds;
        if (!deletedRoleIds.includes(roleId)) {  
            deletedRoleIds.push(roleId);
        }
        this.setState({ deletedRoleIds: deletedRoleIds });
      }

      undeleteRole(roleId) {
        let deletedRoleIds = this.state.deletedRoleIds;
        for(let i=0; i<deletedRoleIds.length; i++) {
            if(deletedRoleIds[i] === roleId) {
                deletedRoleIds.splice(i, 1);
                break;
            }
        }
        this.setState({ deletedRoleIds: deletedRoleIds });
      }

      saveChanges() {
        let edits = this.state.editedRoles;
        let deletes = this.state.deletedRoleIds;
        let adds = this.state.newRoles;
        if (edits.length > 0) {
            console.log('edited roles found....saving ' + edits.length + ' records...');
            for(let i=0; i<edits.length; i++) {
                let payload = { id: edits[i].Id, title: edits[i].Title, description: edits[i].Description };
                EditRole(payload);
                console.log('edited role ' + edits[i].Id + ' saved');
            }
        }
        if (deletes.length > 0) {
            console.log('deleted roles found....deleting ' + deletes.length + ' records...');
            for(let i=0; i<deletes.length; i++) {
                let payload = { id: deletes[i] };
                DeleteRole(payload);
            }
        }
        if (adds.length > 0) {
            console.log('new roles found....adding ' + adds.length + ' records...');
            for(let i=0; i<adds.length; i++) {
                let payload = { id: adds[i].Id, title: adds[i].Title, description: adds[i].Description };
                AddRole(payload);
                console.log('new role ' + adds[i].Id + ' saved');
            }
        }

      }

      isSaveEnabled() {
        return this.state.editedRoleIds.length > 0 || this.state.deletedRoleIds.length > 0 || this.state.newRoleIds.length > 0 ? true : false;  
      }

      isUndeleteEnabled(roleId) { 
        return this.state.deletedRoleIds.includes(roleId) ? true : false;
      }

      isDeleteEnabled(roleId) {
        return this.state.deletedRoleIds.includes(roleId) ? false : true;
      }

      editRoleTitle(role, event) { 
        let editedRoles = this.state.editedRoles;
        let editedRoleIds = this.state.editedRoleIds;
        role.Title = event.target.value;
        if(!editedRoleIds.includes(role.Id)) {
            editedRoles.push(role);
            editedRoleIds.push(role.Id);
        } else {
            let roleIndex = editedRoleIds.findIndex(roleId => roleId === role.Id);
            editedRoles[roleIndex] = role;
        }
        this.setState({ editedRoles: editedRoles, editedRoleIds: editedRoleIds });
      }

      editRoleDescription(role, event) {
        let editedRoles = this.state.editedRoles;
        let editedRoleIds = this.state.editedRoleIds;
        role.Description = event.target.value;
        if (!editedRoleIds.includes(role.Id)) {
            editedRoles.push(role);
            editedRoleIds.push(role.Id);
        } else {
            let roleIndex = editedRoleIds.findIndex(roleId => roleId === role.Id);
            editedRoles[roleIndex] = role;
        }
        this.setState({ editedRoles: editedRoles, editedRoleIds: editedRoleIds });
      }

      previousRole() {
        let thisRoleIndex = this.state.allRoles.findIndex(role => role.Id === this.state.selectedRole.Id);
        let previousRoleIndex = thisRoleIndex - 1;
        if (previousRoleIndex < 0) previousRoleIndex = this.state.allRoles.length - 1;
        let previousRole = this.state.allRoles[previousRoleIndex];
        this.setState({ selectedRole: previousRole });

      }

      nextRole() {
        let thisRoleIndex = this.state.allRoles.findIndex(role => role.Id === this.state.selectedRole.Id);
        let nextRoleIndex = thisRoleIndex + 1;
        if (nextRoleIndex > this.state.allRoles.length - 1) nextRoleIndex = 0;
        let nextRole = this.state.allRoles[nextRoleIndex];
        this.setState({ selectedRole: nextRole });
      }

      componentDidUpdate() {
        if (!this.state.rolesLoaded && this.props.allRoles) {
            this.setState({ allRoles: this.props.allRoles, originalRoleArray: this.props.allRoles, rolesLoaded: true, selectedRole: this.props.allRoles[0] });
        }
      }

      componentDidMount() {
        if (!this.state.rolesLoaded && this.props.allRoles) {
            this.setState({ allRoles: this.props.allRoles, originalRoleArray: this.props.allRoles, rolesLoaded: true, selectedRole: this.props.allRoles[0] });
        }
      }

      render() {
        let roleCount = this.state.allRoles ? this.state.allRoles.length : 0;
        return (
            <div className='app-roles-edit'>
                <div className='app-admin-view-edit-section-header'>Edit Roles</div>
                <div className='app-edit-container'>
                  {this.state.rolesLoaded && this.state.allRoles && this.state.allRoles.map((role, index) => {
                    return ( this.state.selectedRole && this.state.selectedRole.Id === role.Id ? 
                        <div className='app-admin-view-edit-section-item' key={role.Id}>
                            <div className='app-admin-view-edit-section-item-wrapper'>
                                <span className='app-admin-view-edit-label'>Title</span>
                                {this.isUndeleteEnabled(role.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-undelete' onClick={() => this.undeleteRole(role.Id)}><span className='app-small-icon-undelete'></span><FaTrashArrowUp /></span>)}
                                {this.isDeleteEnabled(role.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-delete' onClick={() => this.deleteRole(role.Id)}><span className='app-small-icon-delete'></span><FaTrash /></span>)}
                                
                                <input className={this.isDeleteEnabled(role.Id) ? 'app-admin-view-edit-input' : 'app-admin-view-edit-input-deleted'} defaultValue={role.Title} onChange={(e) => this.editRoleTitle(role, e)} />
                                <span className='app-admin-view-edit-label'>Description</span>
                                <textarea  className={this.isDeleteEnabled(role.Id) ? 'app-admin-view-edit-textarea' : 'app-admin-view-edit-textarea-deleted'} defaultValue={role.Description} onChange={(e) => this.editRoleDescription(role, e)} />
                            </div>
                            <div className='app-admin-view-edit-section-item-buttons'>
                                <span className={index > 0 ? 'app-admin-view-edit-section-item-button-previous' : 'app-small-icon-disabled'} onClick={() => this.previousRole(role.Id)}><Chevron direction={'left'}  /></span>
                                <span className={index < roleCount ? 'app-admin-view-edit-section-item-button-next' : 'app-small-icon-disabled'} onClick={() => this.nextRole(role.Id)}><Chevron direction={'right'}  /></span>
                            </div>
                        </div>
                        : ''
                    )
                  })}    
                  <div className='app-admin-view-edit-buttons'>
                      <span className='app-small-icon-add' onClick={() => this.addRole()}><span className='app-small-icon-plus'></span><FaSquarePlus /></span>
                      <span className={this.isSaveEnabled() ? 'app-small-icon-save' : 'app-small-icon-disabled'} onClick={() => this.saveChanges()}><span className='app-small-icon-save'></span><FaFloppyDisk /></span>    
                  </div>
                </div>

                
            </div>          
        )
      }

}

export default Roles;