import React from 'react';
import Chevron from 'react-chevron';

import SkillSets from './components/Resume/skillSets.js';
import Biography from './components/Resume/biography.js';
import CurrentStatus from './components/Resume/currentStatus.js';
import SiteNav from './components/Shared/siteNav.js';
import Loader from './components/Shared/loader.js';

import GetEmployers from '../api/Resume/allEmployers.js';
import AllProjects from '../api/Resume/allProjects.js';
import AllUpdates from '../api/Resume/allUpdates.js';
import CurrentEmployer from '../api/Resume/currentEmployer.js';
import GetCurrentStatus from '../api/Resume/getCurrentStatus.js';
import SkillSetsByEmployer from '../api/Resume/skillSetsByEmployer.js';

class ResumeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updates: [],
            currentEmployer: {},
            employers: [],
            projects: [],
            currentStatus: {},
            skillSets: [],
            dataLoaded: false,
            resumeLoaded: false,
            currentEmployerProjects: [],
            historicalProjects: [],
            selectedEmployer: {},
            selectedProject: {},
            selectedEmployerLoaded: false,
            selectedProjectLoaded: false,
            selectedEmployerSkillsLoaded: false,
            showEdit: false,
            isLoading: true,
        };
    }

    loadEmployer(employerId, callback) {
        let selectedEmployer = {};
        let employers = this.state.employers;

        for(let i = 0; i < employers.length; i++) {
            if (employers[i].Id === employerId) {
                selectedEmployer = employers[i];
                break;
            }
        }
        selectedEmployer.IsCurrentJob = this.state.currentEmployer.Id === employerId ? true : false;
        selectedEmployer.projects = [];
        
        if(callback) callback(selectedEmployer);
        else return selectedEmployer;
       
    }

    loadSelectedProject(projectId, callback) {
        let selectedEmployer = this.state.selectedEmployer;
        if(selectedEmployer.projects.length > 0) {
            let selectedProject = null;
            for(let i = 0; i < selectedEmployer.projects.length; i++) {
                if(selectedEmployer.projects[i].Id === projectId) {
                    selectedProject = selectedEmployer.projects[i];
                    selectedProject.nextProjectId = selectedEmployer.projects[i+1] ? selectedEmployer.projects[i+1].Id : null;
                    selectedProject.previousProjectId = i > 0 && selectedEmployer.projects[i-1] ? selectedEmployer.projects[i-1].Id : null;
                    break;
                }
            }
            if(callback) {
                callback(selectedProject);
            } else {
                this.setState({ selectedProject: selectedProject, selectedProjectLoaded: true, showNoProjectsMessage: false });
                if (selectedProject === null) this.setState({ showNoProjectsMessage: true });
            } 
        }
    }

    getSelectedEmployerProjects(employerId) {
        let allProjects = this.state.projects;
        let thisEmployerProjects = [];
        let allOtherEmployers = [];
        for(let i = 0; i < allProjects.length; i++) {
            if (allProjects[i].EmployerId === employerId) {
                thisEmployerProjects.push(allProjects[i]);
            }
            else allOtherEmployers.push(allProjects[i]);
        }

        //identify the next and previous
        for(let i = 0; i < thisEmployerProjects.length; i++) {
            thisEmployerProjects[i].nextProjectId = thisEmployerProjects[i+1] ? thisEmployerProjects[i+1].Id : null;
            thisEmployerProjects[i].previousProjectId = i > 0 && thisEmployerProjects[i-1] ? thisEmployerProjects[i-1].Id : null;
        }

        if (thisEmployerProjects.length === 0) {
            let blankRecordForRendering = {
                Id: 0,
                Title: 'n/a',
                Description: 'This employer has no projects listed.',
                ProjectRole: '',
                StartDate: '',
                EndDate: '',
                TeamSize: '',
                ProjectStatus: '',
                hideMe: true
            };
            thisEmployerProjects.push(blankRecordForRendering);
        }

        return thisEmployerProjects;
    }

    selectEmployer(employerId) {
        this.setState({ selectedEmployerLoaded: false });
        let selectedEmployer = this.loadEmployer(employerId);
        this.setState({ selectedEmployer: selectedEmployer, selectedEmployerLoaded: true, selectedEmployerProjectsLoaded: false, selectedEmployerSkillsLoaded: false });          
    }
    selectPreviousEmployer() {
        let selectedEmployer = this.loadEmployer(this.state.selectedEmployer.PreviousEmployerId);
        let projects = this.getSelectedEmployerProjects(selectedEmployer.Id);
        selectedEmployer.projects = projects;
        let selectedProject = projects[0];
        this.setState({ selectedEmployer: selectedEmployer, selectedEmployerProjects: projects, selectedProject: selectedProject});
        let that = this;
        this.getEmployerSkillSets(selectedEmployer.Id, function(skills) {
            selectedEmployer.Skills = skills;
            that.setState({ selectedEmployer: selectedEmployer });   
        });         
    }
    selectNextEmployer() {
        let selectedEmployer = this.loadEmployer(this.state.selectedEmployer.NextEmployerId);
        let projects = this.getSelectedEmployerProjects(selectedEmployer.Id);
        selectedEmployer.projects = projects;
        let selectedProject = projects[0];
        this.setState({ selectedEmployer: selectedEmployer, selectedEmployerProjects: projects, selectedProject: selectedProject});
        let that = this;
        this.getEmployerSkillSets(selectedEmployer.Id, function(skills) {
            selectedEmployer.Skills = skills;
            that.setState({ selectedEmployer: selectedEmployer });   
        });               
    }

    getSkillSets() {
        let allSkillSets = this.state.skillSets;
        let filteredSkillSets = [];

        //this is the general skillset call - pass all individual skills
        let basicSkillSetsAdded = [];
        for(let i=0; i<allSkillSets.length; i++) {
            if(basicSkillSetsAdded.indexOf(allSkillSets[i].SkillSet) === -1) {
                basicSkillSetsAdded.push(allSkillSets[i].SkillSet);
                let skillSet = { name: allSkillSets[i].SkillSet, usage: allSkillSets[i].UsageStatement, inCurrentUse: allSkillSets[i].InCurrentUse };
                filteredSkillSets.push(skillSet);
            }
        }
        this.setState({ skillSetsLoaded: true, allSkillSets: filteredSkillSets });
    }

    getEmployerSkillSets(employerId, callback) {
        let employers = this.state.employers;
        let thisSelectedEmployer = {};
        for(let i=0; i<employers.length; i++) {
            if(employers[i].Id === employerId) {
                thisSelectedEmployer = employers[i];
                break;
            }
        }

        let allSkillSets = this.state.skillSets;
        let filteredSkillSets = [];

        let projectSkillsAdded = [];
        for(let i=0; i<allSkillSets.length; i++) {
            if(allSkillSets[i].EmployerId === this.state.selectedEmployer.Id && projectSkillsAdded.indexOf(allSkillSets[i].SkillSet) === -1) {
                projectSkillsAdded.push(allSkillSets[i].name);
                let skillSet = { name: allSkillSets[i].SkillSet, usage: allSkillSets[i].UsageStatement, inCurrentUse: allSkillSets[i].InCurrentUse };
                filteredSkillSets.push(skillSet);
            }
        }

        thisSelectedEmployer.Skills = filteredSkillSets;
        

        callback(thisSelectedEmployer);
    }

    loadResume() {
        this.setState({ resumeLoaded: false, isLoading: true, dataLoaded: false});
        let that = this;
        GetEmployers(function(response) { that.setState({ employersLoaded: true, employers: response.data }) });
        AllProjects(function(response) { that.setState({ projectsLoaded: true, projects: response.data }) });
        CurrentEmployer(function(response) { that.setState({ currentEmployerLoaded: true, currentEmployer: response.data }) });
        AllUpdates(function(response) { that.setState({ updatesLoaded: true, updates: response.data }) });
        GetCurrentStatus(function(response) { that.setState({ currentStatusLoaded: true, currentStatus: response.data }) });
        SkillSetsByEmployer(function(response) { that.setState({ skillsetsLoaded: true, skillSets: response.data }, function() {
            that.getSkillSets();    //formats and sets state.allSkillSets
        }) });
    }
   

    componentDidUpdate() {
        if(this.state.employersLoaded && this.state.currentEmployerLoaded && this.state.currentStatusLoaded && this.state.updatesLoaded && this.state.projectsLoaded && !this.state.dataLoaded && !this.state.resumeLoaded) {
            if(this.state.currentEmployerLoaded && !this.state.currentEmployerProjectsLoaded) {
                let currentEmployerProjects = this.getSelectedEmployerProjects(this.state.currentEmployer.Id);
                this.setState({ currentEmployerProjects: currentEmployerProjects, currentEmployerProjectsLoaded: true });
            }
                
            if(this.state.currentEmployerLoaded && this.state.currentEmployerProjectsLoaded && !this.state.selectedEmployerLoaded) {
                this.selectEmployer(this.state.currentEmployer.Id);
            }

            if(this.state.selectedEmployerLoaded && !this.state.selectedEmployerProjectsLoaded) {
                let selectedEmployerProjects = this.getSelectedEmployerProjects(this.state.selectedEmployer.Id);
                let selectedEmployer = this.state.selectedEmployer;
                selectedEmployer.projects = selectedEmployerProjects;
                this.setState({ selectedEmployer: selectedEmployer, selectedEmployerProjects: selectedEmployerProjects, selectedEmployerProjectsLoaded: true });
            }

            if(this.state.selectedEmployerProjectsLoaded && !this.state.selectedProjectLoaded) {
                let selectedProject = this.state.selectedEmployerProjects[0];
                this.setState({ selectedProject: selectedProject, selectedProjectLoaded: true });
            }

            if(this.state.selectedEmployerLoaded && this.state.selectedEmployerProjectsLoaded && !this.state.selectedEmployerSkillsLoaded) {
                let that = this;
                this.getEmployerSkillSets(this.state.selectedEmployer.Id, function(selectedEmployer) {
                    that.setState({ selectedEmployer: selectedEmployer, selectedEmployerSkillsLoaded: true });
                });
            }

            if(this.state.currentEmployerLoaded && this.state.currentEmployerProjectsLoaded && this.state.selectedEmployerLoaded && this.state.selectedEmployerProjectsLoaded && this.state.selectedEmployerSkillsLoaded) {
                this.setState({ dataLoaded: true, resumeLoaded: true, isLoading: false });
            }
        } 
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        let user = JSON.parse(window.sessionStorage.getItem('user'));
        if(user && user.userId === 1) {
          this.setState({ showEdit: true });
        }
        this.loadResume();
    }

    render() {
        return (   
            <div>
               <Loader isLoading={this.state.isLoading} />
                <div className='app-resume-container'>
                    <SiteNav />
                    <div className='app-resume-view'>
                        <div className='app-resume-header'>Julie McFadden <span className='app-resume-job-search-status'>{this.state.currentStatus.JobSearchStatusDetails}</span></div>
                        <div className='app-resume-header--last-updated'> {this.state.currentStatus.LastUpdate} </div>
                        <div className='app-resume-header-modal-buttons'>
                            {this.state.resumeLoaded && this.state.currentStatus && <Biography currentStatusArray={this.state.currentStatus} />}
                            {this.state.resumeLoaded && this.state.allSkillSets && <SkillSets skillSets={this.state.allSkillSets}  />}
                            {this.state.resumeLoaded && this.state.currentStatus && <CurrentStatus currentStatusArray={this.state.currentStatus} />}
                        </div>
                        <div className='app-resume-current-employer'>
                            <div className={this.state.selectedEmployer.Name === this.state.currentEmployer.Name ? 'app-resume-employer-header-container' : 'app-resume-employer-header-container-previous'}>
                                <span className={this.state.selectedEmployer.Name === this.state.currentEmployer.Name ? 'app-resume-current-employer--name' : 'app-resume-previous-employer--name'}>{this.state.selectedEmployer.Name}</span>
                                <span className='app-resume-current-employer--item'>{this.state.selectedEmployer.Description}</span>
                                <span className={!this.state.selectedEmployer.EndDate ? 'app-resume-current-employer--daterange' : 'app-resume-employer--daterange'}>
                                    {this.state.selectedEmployer.StartDate}
                                    {this.state.selectedEmployer.EndDate ? ' - ' + this.state.selectedEmployer.EndDate : ''}
                                </span>
                            </div>
                            <div className='app-resume-employer-project-container'>
                                <div className='app-resume-employer-details'>{this.state.selectedEmployer.DetailedDescription}</div>
                                <div className='app-hidden app-resume-employer-skillsets'>{this.state.resumeLoaded && this.state.selectedEmployerSkillsLoaded && <SkillSets skillSets={this.state.selectedEmployer.Skills} />}</div>
                                {this.state.selectedProjectLoaded && !this.state.showNoProjectsMessage && this.state.selectedEmployer.projects.map((project, i) => 
                                    <div key={i} className={this.state.selectedProject && this.state.selectedProject.Id === project.Id ? 'app-resume-project-card card-selected' : 'app-resume-project-card app-hidden'}>
                                        <div key={i + '_container'} className='app-resume-project-card-container'>
                                            <div className='app-resume-project-card-previous'>
                                                {project.previousProjectId > 0 ? <span title='View Previous Project Details' onClick={() => this.loadSelectedProject(project.previousProjectId)}><Chevron direction={'left'} /></span> : ''}
                                            </div>
                                            <div key={i + '_header_title'} className={project.hideMe ? 'app-hidden' : 'app-resume-project-card-header-container'}>
                                                <span key={i + '_title'} className={project.hideMe ? 'app-hidden' : 'app-resume-employer-project-title'}>{project.Title}</span> 
                                                <span key={i + '_role'} className={project.hideMe ? 'app-hidden' : 'app-resume-employer-project-role'}>{project.ProjectRole}</span>
                                                <span key={i + '_status'} className={project.hideMe ? 'app-hidden' : 'app-resume-employer-project-status'}>{project.ProjectStatus}</span>
                                            </div>
                                            <div key={i + '_header_details'} className={project.hideMe ? 'app-hidden' : 'app-resume-project-card-header-container'}>
                                                <span key={i + '_teamsize'} className='app-resume-employer-project-teamsize'>{project.TeamSize}</span>
                                                <span key={i + '_daterange'} className='app-resume-employer-project-daterange'>{project.StartDate} - {project.EndDate}</span>
                                            </div>
                                            <div key={i + '_details'} className={project.hideMe ? 'app-resume-project-details-no-project' : 'app-resume-project-details-container'}>
                                                <div key={i + '_description'} className='app-resume-employer-project-description'>{project.Description}</div>
                                            </div>
                                            <div className='app-resume-project-card-next'>
                                                {project.nextProjectId > 0 ? <span title='View Next Project Details' onClick={() => this.loadSelectedProject(project.nextProjectId)}><Chevron direction={'right'} /></span> : ''}
                                            </div>
                                        </div>
                                    </div>   
                                )}
                            </div>
                        </div>

                    </div>

                        <div className='app-resume-navigation-container'>
                            {this.state.resumeLoaded && this.state.selectedEmployer.PreviousEmployerId !== null && (<button className='app-resume-navigation-previous' onClick={(event) => this.selectPreviousEmployer()}>previous</button>)}
                            {this.state.resumeLoaded && this.state.selectedEmployer.PreviousEmployerId === null&& (<button className='app-resume-navigation-previous--disabled'>previous</button>)}
                            {this.state.resumeLoaded && this.state.selectedEmployer.NextEmployerId !== null && (<button className='app-resume-navigation-next' onClick={(event) => this.selectNextEmployer()}>next</button>)}
                            {this.state.resumeLoaded && this.state.selectedEmployer.NextEmployerId === null && (<button className='app-resume-navigation-next--disabled'>next</button>)}
                        </div>  

                </div>
            </div>            
        )
    }
}

export default ResumeView;