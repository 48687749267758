import React from 'react';

class JournalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            journalEntries: [],
            modalIsOpen: false,
            selectedJournal: {}
        }
    }

    componentDidUpdate() {}
    componentDidMount() {
        console.log(this.props);
    }

    render() {
        return (
            <div className='app-journal-view'>
                Julie's Journal
            </div>
        )
    }
}

export default JournalView;