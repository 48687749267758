import React, { useRef }  from 'react';

import AllKeyCodes from '../../helpers/keyCodes.js';
import CommandHistory from '../Console/commandHistory.js';
import Help from '../Console/helpCommand.js';
import Login from '../../../api/Auth/login.js';
import Reset from '../../../api/Auth/reset.js';
import Register from '../../../api/Auth/register.js';
import PowerButton from '../VR/PowerButton.js';

function VRCommandPrompt(props) {
    var user = props.user;
    var activeCommand = props.activeCommand;
    let bannerLoaded = false;

    const textInputRef = useRef();
    const invalidInputRef = useRef();
    let historyElement = useRef();

    const bannerBorder = '*****************************************************************************';
    const bannerHeader = '************************************* Julie\'s Playground ********************';
    const bannerText = [{text: bannerBorder, ref: 'banner1'}, {text: bannerHeader, ref: 'banner2'}, {text: bannerBorder, ref: 'banner3'}];
    const shutDownText = '...powering down....goodbye...';
    
    let renderedBanner1 = '';
    let renderedBanner2 = '';
    let renderedBanner3 = '';
    let renderedShutDown = '';

    function escapeCommand() {
        props.setCommandText('');
        props.setCommandHistory([]);
        props.setActiveCommand('');
        props.setCommandPrompt('enter command');
    }

    function setFocus() {
        textInputRef.current.focus();
    }

    function isRedirectCommand(command) {
        if(props.redirectCommands && props.redirectCommands.indexOf(command) >= 0) return true;
        return false;
    }

    function isSecureRedirectCommand(command) {
        if(props.secureRedirectCommands && props.secureRedirectCommands.indexOf(command) >= 0) return true; 
        return false;
    }

    function isSecureCommand(command) {
        if(props.secureCommands && props.secureCommands.indexOf(command) >= 0) return true;
        return false;
    }

    function enterCommand() {
        textInputRef.current.value = '';

        //set restricted commands (require params)
        let restrictedCommands = ['user name', 'password', 'new password', 'user role', 'display name', 'role'];
        activeCommand = restrictedCommands.indexOf(props.commandPrompt) < 0 ?  props.commandText : props.activeCommand;
        if(props.activeCommand !== activeCommand)
            props.setActiveCommand(props.commandText);

        //run the command
        runCommand();

    }
    function readInput(event) {
        //check for allowed characters
        if(AllKeyCodes.allowedKeyCodes.indexOf(event.keyCode) < 0) {
            props.setInvalidInputEntry(true);
            return;
        } 
        props.setInvalidInputEntry(false);

        let keyValue = event.key;
        
        //check for max length
        if(props.commandText.length === 49 && event.keyCode !== 8 && event.keyCode !== 13 && event.key !== 'Enter') {
            props.setInvalidInputEntry(true);
            return;
        }
        if(AllKeyCodes.specialKeyCodes.indexOf(event.keyCode) >= 0) {
            //escape -- get out of the active command
            if (event.keyCode === 27) {
                escapeCommand();
                return;
            }
            //shift -- do nothing
            if (event.keyCode === 16) return; 

            //question mark - show help
            if (event.keyCode === 191) {
                props.setCommandText('?');
                return;
            }
    
            //backspace
            if (event.keyCode === 8) {
                var previousEntry = props.commandText;
                props.setCommandText(previousEntry.substring(0, previousEntry.length -1));
                return;
            }

            //run the command, update the history
            if (event.key === 'Enter') {
                props.setCommandText(props.commandText);
                enterCommand();
                return;
            } 
        }
        
        //alphanumerics - build the command
        if (AllKeyCodes.alphaNumericsAndSymbols.indexOf(event.keyCode) >= 0) {
            props.setCommandText(props.commandText + keyValue);
        } 

    };

    function runCommand() {
        //is this a redirect command?
        let isRedirect = isRedirectCommand(props.commandText) || isSecureRedirectCommand(props.commandText);
        let isSecure = isSecureCommand(props.commandText) || isSecureRedirectCommand(props.commandText);
        if(isRedirect) {
            if (isSecure) {
                //check for logged in user
                if(props.loggedInUser) {
                    //redirect to the command
                    props.redirectHandler(props.commandText)
                } else {
                    props.setCommandHistory([...props.commandHistory, props.commandText + '...unauthorized']);
                }
            } else {
                props.redirectHandler(props.commandText);
            }
            return;
        }

        //clear out the command text
        props.setCommandText('');

        //and now run the command
        switch(activeCommand) {
            case 'clear':
                props.setCommandHistory([]);
                props.setCommandPrompt('enter command');
                break;
            case '?':
            case 'help':
                props.setCommandHistory([...props.commandHistory, 'help']);
                break;
            case 'reset':
                props.setActiveCommand('reset');
                if(user.userName === '') props.setCommandPrompt('user name');
                if(props.commandPrompt === 'user name' && user.userName === '') {
                    user.userName = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('new password');
                }
                if(props.commandPrompt === 'new password' && user.password === '') {
                    user.password = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('...resetting...');
                    resetUser(user.userName, user.password, function(results) {
                        props.setCommandHistory([...props.commandHistory, results.data]);
                        props.setCommandPrompt('enter command');
                        props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                        props.setActiveCommand('');
                    });
                }
                break;
            case 'login':
                props.setActiveCommand('login');
                if(user.userName === '') props.setCommandPrompt('user name');
                if(props.commandPrompt === 'user name' && user.userName === '') {
                    user.userName = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('password');
                }
                if(props.commandPrompt === 'password' && user.password === '') {
                    user.password = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('...logging in...');
                    loginUser(user.userName, user.password, function(results) {
                        if(results.userId > 0) {
                            window.sessionStorage.setItem('user', JSON.stringify(results));
                            props.setCommandPrompt('enter command');
                            props.setCommandHistory([...props.commandHistory, 'Welcome, ' + results.displayName + '!  What would you like to do today?']);
                        } else {
                            props.setCommandHistory([...props.commandHistory, 'login failed']);
                            props.setCommandPrompt('enter command');
                            props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                            props.setActiveCommand('');
                        }
                    });
                }
                break;
            case 'register':
                if(props.loggedInUser && props.loggedInUser.role === 'Owner') {
                    console.log(user, props.user);
                    props.setActiveCommand('register');
                    if(user.userName === '') props.setCommandPrompt('user name');
                    if(props.commandPrompt === 'user name' && user.userName === '') {
                        props.setActiveCommand('register');
                        user.userName = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('password');
                    }
                    if(props.commandPrompt === 'password' && user.password === '') {
                        props.setActiveCommand('register');
                        user.password = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('display name');
                    }
                    if(props.commandPrompt === 'display name' && user.displayName === '') {
                        props.setActiveCommand('register');
                        user.displayName = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('user role');
                    }
                    if(props.commandPrompt === 'user role' && user.role === '') {
                        props.setActiveCommand('register');
                        user.role = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('...registering user...');
                        registerUser(user.userName, user.password, user.displayName, user.role, function(results) {
                            props.setCommandHistory([...props.commandHistory, user.displayName + ' successfully created!']);
                            props.setCommandPrompt('enter command');
                            props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                            props.setActiveCommand('');
                        });    
                    }                   
               }
               break;
             case 'logout':
                window.sessionStorage.removeItem('user');
                props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                props.setCommandHistory([...props.commandHistory, 'logout']);
                props.setCommandPrompt('enter command');
                break;
            default:
                props.setCommandHistory([...props.commandHistory, props.commandText]);
                props.setCommandText('');
                break;
        }
    }


    function userHasPrivilege(accessRoleName) {
        if(props.loggedInUser && props.loggedInUser.role === accessRoleName) return true;
        else return false;
    }

    async function loginUser(username, password, callback) {
        if(username !== '' && password !== '') {
            await Login({ userName: username, password: password }, (results) => {
                console.log(results);
                if (results.status === 200) {
                    callback(results.data)
                }
            });
        }
    }

    async function resetUser(username, password, callback) {
        if(username !== '' && password !== '') {
            await Reset({ userName: username, password: password }, (results) => {
                if (results.status === 200) {
                    callback(results.data)
                }
            });
        }
    }

    async function registerUser(username, password, displayName, role, callback) {
        if(username !== '' && password !== '' && displayName !== '' && role !== '') {
            await Register({ userName: username, password: password, displayName: displayName, role: role }, (results) => {
                if (results.status === 200) {
                    callback(results.data)
                }
            });
        }
    }

    function renderCommandHistory() {
        if(props.commandHistory && props.commandHistory.length > 0) {
            let lastCommand = props.commandHistory[props.commandHistory.length - 1];
            switch(lastCommand) {
                case 'help':
                    //show the available options based off of logged in user
                    var showRegister = props.loggedInUser && userHasPrivilege('Owner');
                    var showLogin = !props.loggedInUser;
                    var showLogout = props.loggedInUser;
                    var showPlay = showRegister;

                    return ( <Help showLogin={showLogin} showRegister={showRegister} showLogout={showLogout} showPlay={showPlay} /> )
                default:
                    return ( <CommandHistory history={props.commandHistory} /> )
                    
            }
        }
    }

    function togglePowerOff() {
        clearBanner();
        props.setPowerStatus('powering');
        props.setPoweringOff(true);
        startShutDownTextAnimation(0, function() {
            props.setPoweringOff(false);
            props.setPoweredOn(false);
            props.setPowerStatus('off');
        });
    }

    function togglePowerOn() {
        clearBanner();
        props.setPowerStatus('powering');
        props.setPoweringOn(true);
        startPowerOnTextAnimation(0, function() {
            props.setPoweringOn(false);            
            props.setPoweredOn(true);
            props.setPowerStatus('on');
        });
    }

    function clearBanner() {
          //clear the banner
          props.setBannerTextLine1('');
          props.setBannerTextLine2('');
          props.setBannerTextLine3('');      
    }


    function renderTypeAffect(text, i, bannerRef, callback) {
        //unfinished
        if (i < text.length) {
            if(bannerRef === 'banner1') {
                let banner1 = props.bannerTextLine1 ? props.bannerTextLine1 : '';
                let newValue1 = banner1 + text[i];
                renderedBanner1 += newValue1;
                props.setBannerTextLine1(renderedBanner1);
            }
            if(bannerRef === 'banner2') {
                let banner2 = props.bannerTextLine2 ? props.bannerTextLine2 : '';
                let newValue2 = banner2 + text[i];
                renderedBanner2 += newValue2;
                props.setBannerTextLine2(renderedBanner2);
            }    
            if(bannerRef === 'banner3') {
                let banner3 = props.bannerTextLine3 ? props.bannerTextLine3 : '';
                let newValue3 = banner3 + text[i];
                renderedBanner3 += newValue3;
                props.setBannerTextLine3(renderedBanner3);
            }      
            if(bannerRef === 'shutdown') {
                let shutdown = props.shutDownText ? props.shutDownText : '';
                let newValue4 = shutdown + text[i];
                renderedShutDown += newValue4;
                props.setShutDownText(renderedShutDown);
            }        
            setTimeout(function() {
                renderTypeAffect(text, i+1, bannerRef, callback);
            }, 100);
        } else {
            if (typeof callback === 'function') setTimeout(callback, 700)
        }
    }

    function startShutDownTextAnimation(i, callback) {
        renderTypeAffect(shutDownText, 0, 'shutdown', function() {
            props.setShutDownText('');
            callback(true);         
        });
    }


    function startPowerOnTextAnimation(i, callback) {
        if (!bannerLoaded) {
            if (typeof bannerText[i] === 'undefined') {
                setTimeout(function() {
                    startPowerOnTextAnimation(0);
                }, 2000);
            }

            if (i < bannerText.length) {
                renderTypeAffect(bannerText[i].text, 0, bannerText[i].ref, function() {
                    if(i < bannerText.length) startPowerOnTextAnimation(i+1, callback);
                });
            }

            if (i === bannerText.length) {
                bannerLoaded = true;
                setFocus();
                if (typeof callback === "function") callback(true);
            }
        }
    }

    return (
        <div id="vrTextOverlay">
            <div id="vrBanner" className='app-vr-console-text'>
                <span>{props.bannerTextLine1}</span><br/>
                <span>{props.bannerTextLine2}</span><br/>
                <span className='app-push-down-5'>{props.bannerTextLine3}</span><br/>
            </div>
            <div className={props.commandHistory && props.commandHistory.length > 0 && props.poweredOn ? 'app-console-history' : 'app-hidden'}>
                {renderCommandHistory()}
            </div>
            <div className={props.commandHistory && props.commandHistory.length > 0 && props.poweredOn ? 'app-console-history-end' : 'app-hidden'} ref={historyElement}></div>
            <span id='vrEnterCommand' className={props.poweredOn && !props.poweringOff ? '' : 'app-hidden'}>
                <span className='app-console-command-prompt'>{props.commandPrompt}</span> 
                <span className={props.commandPrompt === 'password' ? 'app-console-command-pwd' : 'app-console-command-text'}>{props.commandText}</span>
            </span>
            <input type={props.commandPrompt === 'password' ? 'password' : 'text'} maxLength='200' spellCheck='false' className='app-console-input' id='vrTextInput' ref={textInputRef} autoComplete='off' autoFocus onKeyDown={e => readInput(e)} onBlur={setFocus}></input>
            <div className={props.invalidInputEntry ? '' : 'app-hidden'} id='vrInvalidInput' ref={invalidInputRef}>Invalid Input</div>
            <div className={props.poweringOff ? 'app-vr-console-text' : 'app-hidden'}>{props.shutDownText}</div>
            <PowerButton powerStatus={props.powerStatus} powerOffHandler={togglePowerOff} powerOnHandler={togglePowerOn} stageHeight={props.stageHeight} stageWidth={props.stageWidth} />

        </div>
    )

}

export default VRCommandPrompt;