import React from 'react';
import { FaHouseChimney, FaUserNinja, FaUpRightFromSquare, FaMoneyCheckDollar   } from 'react-icons/fa6';

class SiteNav extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showEdit: false,
        showLogout: false,
      };
    }

    goHome = () => {
        document.location = "/";
    }

    goResume = () => {
        document.location = "/resume";
    }

    goAdmin = () => {
        if(this.state.showEdit) document.location = "/admin";
    }
   
    goLogout = () => {
        window.sessionStorage.removeItem('user');
        document.location = "/";
    }
    componentDidUpdate() {

    }

    componentDidMount() {
        let user = JSON.parse(window.sessionStorage.getItem('user'));
        if(user && user.userId === 1) {
          this.setState({ showEdit: true, showLogout: true });
        }
    }
  
    render() {
        return (
            <div className='app-side-nav-icons'>
                <div className='app-home-link'><FaHouseChimney onClick={this.goHome} /></div>
                <div className='app-push-down-20 app-resume-link'><FaMoneyCheckDollar onClick={this.goResume} /></div>
                <div className={this.state.showEdit ? 'app-push-down-20 app-admin-link' : 'app-push-down-20 app-small-icon-disabled'}><FaUserNinja onClick={this.goAdmin} /></div>
                <div className={this.state.showLogout ? 'app-push-down-20 app-logout-link' : 'app-push-down-20 app-small-icon-disabled'}><FaUpRightFromSquare onClick={this.goLogout} /></div>
            </div>
        );
    }

}

export default SiteNav;