import React from 'react';
import consoleBackgroud from '../../../images/consoleBG.jpg';
import AllKeyCodes from '../../helpers/keyCodes.js';
import CommandHistory from '../Console/commandHistory.js';
import Help from '../Console/helpCommand.js';
import Login from '../../../api/Auth/login.js';
import Reset from '../../../api/Auth/reset.js';
import Register from '../../../api/Auth/register.js';

function CommandPrompt(props) {
    const consoleInputRef = React.createRef();
    var historyElement = React.createRef();
    var user = props.user;
    var activeCommand = props.activeCommand;
    
    function escapeCommand() {
        props.setCommandText('');
        props.setCommandHistory([]);
        props.setActiveCommand('');
        props.setCommandPrompt('enter command');
    }

    function setFocus() {
        consoleInputRef.current.focus();
    }

    function isRedirectCommand(command) {
        if(props.redirectCommands && props.redirectCommands.indexOf(command) >= 0) return true;
        return false;
    }

    function isSecureRedirectCommand(command) {
        if(props.secureRedirectCommands && props.secureRedirectCommands.indexOf(command) >= 0) return true; 
        return false;
    }

    function isSecureCommand(command) {
        if(props.secureCommands && props.secureCommands.indexOf(command) >= 0) return true;
        return false;
    }

    function enterCommand() {
        console.log('hi');
        //set restricted commands (require params)
        let restrictedCommands = ['user name', 'password', 'new password', 'user role', 'display name', 'role'];
        activeCommand = restrictedCommands.indexOf(props.commandPrompt) < 0 ?  props.commandText : props.activeCommand;
        if(props.activeCommand !== activeCommand)
            props.setActiveCommand(props.commandText);

        //run the command
        runCommand();

    }
    function readInput(event) {
        //check for allowed characters
        if(AllKeyCodes.allowedKeyCodes.indexOf(event.keyCode) < 0) {
            props.setInvalidInputEntry(true);
            return;
        } 
        props.setInvalidInputEntry(false);

        let keyValue = event.key;
        

        if(AllKeyCodes.specialKeyCodes.indexOf(event.keyCode) >= 0) {
            //escape -- get out of the active command
            if (event.keyCode === 27) {
                escapeCommand();
                return;
            }
            //shift -- do nothing
            if (event.keyCode === 16) return; 

            //question mark - show help
            if (event.keyCode === 191) {
                props.setCommandText('?');
                return;
            }
    
            //backspace
            if (event.keyCode === 8) {
                var previousEntry = props.commandText;
                props.setCommandText(previousEntry.substring(0, previousEntry.length -1));
                return;
            }

            //run the command, update the history
            if (event.key === 'Enter') {
                props.setCommandText(props.commandText);
                enterCommand();
                return;
            } 
        }
        
        //alphanumerics - build the command
        if (AllKeyCodes.alphaNumericsAndSymbols.indexOf(event.keyCode) >= 0) {
            props.setCommandText(props.commandText + keyValue);
        } 

    };

    function runCommand() {
        //is this a redirect command?
        let isRedirect = isRedirectCommand(props.commandText) || isSecureRedirectCommand(props.commandText);
        let isSecure = isSecureCommand(props.commandText) || isSecureRedirectCommand(props.commandText);
        if(isRedirect) {
            if (isSecure) {
                //check for logged in user
                if(props.loggedInUser) {
                    //redirect to the command
                    props.redirectHandler(props.commandText)
                } else {
                    props.setCommandHistory([...props.commandHistory, props.commandText + '...unauthorized']);
                }
            } else {
                props.redirectHandler(props.commandText);
            }
            return;
        }

        //clear out the command text
        props.setCommandText('');

        //and now run the command
        switch(activeCommand) {
            case 'clear':
                props.setCommandHistory([]);
                props.setCommandPrompt('enter command');
                break;
            case '?':
            case 'help':
                props.setCommandHistory([...props.commandHistory, 'help']);
                break;
            case 'reset':
                props.setActiveCommand('reset');
                if(user.userName === '') props.setCommandPrompt('user name');
                if(props.commandPrompt === 'user name' && user.userName === '') {
                    user.userName = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('new password');
                }
                if(props.commandPrompt === 'new password' && user.password === '') {
                    user.password = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('...resetting...');
                    resetUser(user.userName, user.password, function(results) {
                        props.setCommandHistory([...props.commandHistory, results.data]);
                        props.setCommandPrompt('enter command');
                        props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                        props.setActiveCommand('');
                    });
                }
                break;
            case 'login':
                props.setActiveCommand('login');
                if(user.userName === '') props.setCommandPrompt('user name');
                if(props.commandPrompt === 'user name' && user.userName === '') {
                    user.userName = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('password');
                }
                if(props.commandPrompt === 'password' && user.password === '') {
                    user.password = props.commandText;
                    props.setUser(user);
                    props.setCommandPrompt('...logging in...');
                    loginUser(user.userName, user.password, function(results) {
                        if(results.userId > 0) {
                            window.sessionStorage.setItem('user', JSON.stringify(results));
                            props.setCommandPrompt('enter command');
                            props.setCommandHistory([...props.commandHistory, 'Welcome, ' + results.displayName + '!  What would you like to do today?']);
                        } else {
                            props.setCommandHistory([...props.commandHistory, 'login failed']);
                            props.setCommandPrompt('enter command');
                            props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                            props.setActiveCommand('');
                        }
                    });
                }
                break;
            case 'register':
                if(props.loggedInUser && props.loggedInUser.role === 'Owner') {
                    console.log(user, props.user);
                    props.setActiveCommand('register');
                    if(user.userName === '') props.setCommandPrompt('user name');
                    if(props.commandPrompt === 'user name' && user.userName === '') {
                        props.setActiveCommand('register');
                        user.userName = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('password');
                    }
                    if(props.commandPrompt === 'password' && user.password === '') {
                        props.setActiveCommand('register');
                        user.password = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('display name');
                    }
                    if(props.commandPrompt === 'display name' && user.displayName === '') {
                        props.setActiveCommand('register');
                        user.displayName = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('user role');
                    }
                    if(props.commandPrompt === 'user role' && user.role === '') {
                        props.setActiveCommand('register');
                        user.role = props.commandText;
                        props.setUser(user);
                        props.setCommandPrompt('...registering user...');
                        registerUser(user.userName, user.password, user.displayName, user.role, function(results) {
                            props.setCommandHistory([...props.commandHistory, user.displayName + ' successfully created!']);
                            props.setCommandPrompt('enter command');
                            props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                            props.setActiveCommand('');
                        });    
                    }                   
               }
               break;
             case 'logout':
                window.sessionStorage.removeItem('user');
                props.setUser({ userName: '', password: '', action: 'login', role: '', displayName: '' });
                props.setCommandHistory([...props.commandHistory, 'logout']);
                props.setCommandPrompt('enter command');
                break;
            default:
                props.setCommandHistory([...props.commandHistory, props.commandText]);
                props.setCommandText('');
                break;
        }

       
            
        //make sure we're at the bottom of the history list since we'll be adding more
        scrollToBottom();
    }

    function scrollToBottom() {
        historyElement.scrollIntoView({ behavior: 'smooth' });
    }

    function userHasPrivilege(accessRoleName) {
        if(props.loggedInUser && props.loggedInUser.role === accessRoleName) return true;
        else return false;
    }

    async function loginUser(username, password, callback) {
        console.log('loginUser', username, password);
        if(username !== '' && password !== '') {
            await Login({ userName: username, password: password }, (results) => {
                console.log(results);
                if (results.status === 200) {
                    callback(results.data)
                }
            });
        }
    }

    async function resetUser(username, password, callback) {
        if(username !== '' && password !== '') {
            await Reset({ userName: username, password: password }, (results) => {
                if (results.status === 200) {
                    callback(results.data)
                }
            });
        }
    }

    async function registerUser(username, password, displayName, role, callback) {
        if(username !== '' && password !== '' && displayName !== '' && role !== '') {
            await Register({ userName: username, password: password, displayName: displayName, role: role }, (results) => {
                if (results.status === 200) {
                    callback(results.data)
                }
            });
        }
    }

    function renderCommandHistory() {
        if(props.commandHistory && props.commandHistory.length > 0) {
            let lastCommand = props.commandHistory[props.commandHistory.length - 1];
            switch(lastCommand) {
                case 'help':
                    //show the available options based off of logged in user
                    var showRegister = props.loggedInUser && userHasPrivilege('Owner');
                    var showLogin = !props.loggedInUser;
                    var showLogout = props.loggedInUser;
                    var showPlay = showRegister;

                    return ( <Help showLogin={showLogin} showRegister={showRegister} showLogout={showLogout} showPlay={showPlay} /> )
                default:
                    return ( <CommandHistory history={props.commandHistory} /> )
                    
            }
        }
    }


    return (
        <div>
            <div className='app-console-cursor'>
                <div className='app-console-input-container'>
                    <span className='app-console-input-text'>{props.commandPrompt}: {props.commandText}</span>
                    <span className='app-console-blinker'> </span> 
                </div>
            </div>
            <div className='app-console-hidden-input'>
                <input type='text' className='app-console-input'  style={{ backgroundImage: `url(${consoleBackgroud})` }} id='type-here' ref={consoleInputRef} autoFocus onKeyDown={e => readInput(e)} onBlur={setFocus}></input>
            </div>
            <div className='app-console-intro-text'><p className='app-console-inset-1x'>Julie's</p><p className='app-console-inset-2x'>Playground</p></div>
            <div className='app-console-history'>
                {renderCommandHistory()}
            </div>
            <div className='app-console-history-end' ref={el => { historyElement = el; }}></div>
        </div>
    )

}

export default CommandPrompt;