import { apiUrl } from '../config.js';
import axios from 'axios';

function AddStatus(payload) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    if (payload.name !== '') {
        axios({
            method: 'post',
            url: apiUrl + 'addStatus',
            headers: { 'authorization': token, 'Content-Type': 'application/json' },
            data: {
                name: payload.statement,
                description: payload.display,
                setAsCurrent: payload.setAsCurrent
            }
        })
        .catch(function(error) {
            console.log(error);
            return 'fail';
        })
        .then(function(response) {
            if(response.statusText === 'OK') return 'success';
            else return 'fail';
        })}
    else {
        console.log('you have passed an empty status - delete instead')
    }
}

export default AddStatus;