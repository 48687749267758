import React from 'react';
import { FaFloppyDisk, FaTrash, FaSortUp, FaSortDown, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import EditStatement from  '../../../api/Biography/editStatement';
import AddStatement from '../../../api/Biography/addStatement';
import DeleteStatement from '../../../api/Biography/deleteStatement';


class Biography extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          allStatements: [],
          editedStatements: [],
          editedStatementIds: [],
          deletedStatementIds: [],
          newStatements: [],
          newStatementIds: [],
          originalStatementArray: [],
          statement: '',
          statementsLoaded: false,
        };

      }
  
    selectRecord(update) {
      let editedStatementIds = this.state.editedStatementIds;
      let editedStatements = this.state.editedStatements;
      if(editedStatementIds.indexOf(update.Id) < 0) {
        editedStatements.push(update);
        editedStatementIds.push(update.Id);
      }
      this.setState({ statement: update.Statement});
    }

    moveUp(update) {
      let thisDisplayOrder = update.DisplayOrder;
      let myNewDisplayOrder = thisDisplayOrder - 1;
      let allStatements = this.state.allStatements;
      let editedStatements = this.state.editedStatements;
      let editedStatementIds = this.state.editedStatementIds;
      
      allStatements.sort((a, b) => a.DisplayOrder < b.DisplayOrder ? 1 : -1)
      for(let i=0; i < allStatements.length; i++) {
        if (allStatements[i].DisplayOrder < thisDisplayOrder) {
          myNewDisplayOrder = allStatements[i].DisplayOrder;
          allStatements[i].DisplayOrder = thisDisplayOrder;
          if(editedStatementIds.indexOf(allStatements[i].Id) >= 0) {
            for(let i=0; i<editedStatements.length; i++) {
              if (editedStatements[i].Id === allStatements[i].Id) editedStatements[i].DisplayOrder = thisDisplayOrder;
            }
          } else {
            editedStatements.push(allStatements[i]);
            editedStatementIds.push(allStatements[i].Id);
          }
          break; 
        }
      }
      //update this one to use the new display order
      update.DisplayOrder = myNewDisplayOrder;
      //now update this one in the edited statements list or save it in there
      if(editedStatementIds.indexOf(update.Id) >= 0) {
        for(let i=0; i<editedStatements.length; i++) {
          if (editedStatements[i].Id === update.Id) editedStatements[i].DisplayOrder = myNewDisplayOrder;
        }
      } else {
        editedStatements.push(update);
        editedStatementIds.push(update.Id);
      }

      //now update this one in the allstatements list
      let updatedStatementList = [];
      for(let i=0; i<allStatements.length; i++) {
        if(allStatements[i].Id === update.Id) {
          allStatements[i].DisplayOrder = myNewDisplayOrder;
        }
        updatedStatementList.push(allStatements[i]);
      }



      //and now update the state
      this.setState({ allStatements: updatedStatementList, editedStatements: editedStatements, editedStatementIds: editedStatementIds });
      
    }

    moveDown(update) {
      let thisDisplayOrder = update.DisplayOrder;
      let myNewDisplayOrder = thisDisplayOrder - 1;
      let allStatements = this.state.allStatements;
      let editedStatements = this.state.editedStatements;
      let editedStatementIds = this.state.editedStatementIds;
      
      allStatements.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1)
      for(let i=0; i < allStatements.length; i++) {
        if (allStatements[i].DisplayOrder > thisDisplayOrder) {
          myNewDisplayOrder = allStatements[i].DisplayOrder;
          allStatements[i].DisplayOrder = thisDisplayOrder;
          if(editedStatementIds.indexOf(allStatements[i].Id) >= 0) {
            for(let i=0; i<editedStatements.length; i++) {
              if (editedStatements[i].Id === allStatements[i].Id) editedStatements[i].DisplayOrder = thisDisplayOrder;
            }
          } else {
            editedStatements.push(allStatements[i]);
            editedStatementIds.push(allStatements[i].Id);
          }
          break; 
        }
      }
      //update this one to use the new display order
      update.DisplayOrder = myNewDisplayOrder;
      //now update this one in the edited statements list or save it in there
      if(editedStatementIds.indexOf(update.Id) >= 0) {
        for(let i=0; i<editedStatements.length; i++) {
          if (editedStatements[i].Id === update.Id) editedStatements[i].DisplayOrder = myNewDisplayOrder;
        }
      } else {
        editedStatements.push(update);
        editedStatementIds.push(update.Id);
      }

      //now update this one in the allstatements list
      for(let i=0; i<allStatements.length; i++) {
        if(allStatements[i].Id === update.Id) {
          allStatements[i].DisplayOrder = myNewDisplayOrder;
          break;
        }
      }

      let updatedStatementList = allStatements;

      //and now update the state
      this.setState({ allStatements: updatedStatementList, editedStatements: editedStatements, editedStatementIds: editedStatementIds });
      
    }

    editRecord(event, update) {
      let statement = event.target.value;

      let editedStatements = this.state.editedStatements;
      //now save the updated statement to the array for later processing
      for(let i=0; i< editedStatements.length; i++) {
        if(editedStatements[i].Id === update.Id) {
          editedStatements[i].Statement = statement;
          break;
        }
      }   

      //now update the state
      let updatedStatementList = this.state.allStatements;
      for(let i=0; i < updatedStatementList.length; i++) {
        if (updatedStatementList[i].Id === update.Id) {
          updatedStatementList[i].Statement = statement;
          updatedStatementList[i].messageToUser = 'unsaved changes...';
          break;  
        }
      }
      
      this.setState({ allStatements: updatedStatementList, editedStatements: editedStatements });
    }

    saveRecord(updateType, statementId, callback) {
      let payload = { };
      if (updateType === 'edit') { payload.id = statementId };

      //pick up the edited statement from the editedStatementArray
      for(let i = 0; i < this.state.editedStatements.length; i++) {
        if(this.state.editedStatements[i].Id === statementId) {
          payload.statement = this.state.editedStatements[i].Statement;
          payload.remote = this.state.editedStatements[i].Remote;
          payload.locationId = this.state.editedStatements[i].LocationId;
          payload.statusId = this.state.editedStatements[i].JobSearchStatusId;
          payload.display = true;
          payload.displayOrder = this.state.editedStatements[i].DisplayOrder;
          break;
        }
      }

      if (updateType === 'edit') {
        payload.id = statementId;
        EditStatement(payload);
      } else if (updateType === 'new') {
        AddStatement(payload);
      }

      callback('saved!');
      

    }

    saveRecords() {
      let editedStatements = this.state.editedStatements;
      let newStatements = this.state.newStatements;
      let deletedStatementIds = this.state.deletedStatementIds;

      let allStatements = this.state.allStatements;
      let processedStatementIds = [];

      //go through any new ones first
      if(newStatements.length > 0) {
        console.log('SAVING NEW STATEMENTS');
        for (let i=0; i < newStatements.length; i++) {  
          let statementId = newStatements[i].Id;
          console.log('...statementId: ' + statementId);
          if(processedStatementIds.indexOf(statementId) < 0) {  
            for(let n=0; n < allStatements.length; n++) {
              if(allStatements[n].Id === statementId) {
                allStatements[n].messageToUser = 'saving new statement...';
                break;
              }
            }
            console.log('...call saveRecord');
            this.saveRecord('new', statementId, (message) => {
              for(let n=0; n < allStatements.length; n++) {
                if(allStatements[n].Id === statementId) {
                  allStatements[n].messageToUser = message;
                  break;
                }
              }
            });
          }
          console.log('...add to processedStatementIds');
          processedStatementIds.push(statementId);
          console.log('...update the state');
          this.setState({ allStatements: allStatements });
        }
      }

      if(deletedStatementIds.length > 0) {
        console.log('DELETING STATEMENTS');
        let updatedStatementList = [];
        for (let i=0; i < deletedStatementIds.length; i++) {
          let statementId = deletedStatementIds[i];
          console.log('...statementId: ' + statementId);
          if(processedStatementIds.indexOf(statementId) < 0) {
            for(let n=0; n < allStatements.length; n++) {
              if(allStatements[n].Id === statementId) {
                allStatements[n].messageToUser = 'deleting statement...';
              } else {
                updatedStatementList.push(allStatements[n]);
              }
            }
          }
          console.log('...call deleteRecord');
          DeleteStatement(statementId);
          console.log('...add to processedStatementIds');
          processedStatementIds.push(statementId); 
          console.log('...update the state');
          this.setState({ allStatements: updatedStatementList });
        }
      }

      if(editedStatements.length > 0) {
        console.log('SAVING EDITED STATEMENTS');
        for (let i=0; i < editedStatements.length; i++) {
          let statementId = editedStatements[i].Id;
          console.log('...statementId: ' + statementId);
          if(processedStatementIds.indexOf(statementId) < 0) {
          for(let n=0; n < allStatements.length; n++) {
              if(allStatements[n].Id === statementId) {
                allStatements[n].messageToUser = 'saving statement...';
                break;
              }
            }   
          }
          console.log('...call saveRecord');
          this.saveRecord('edit', statementId, (message) => {
            for(let n=0; n < allStatements.length; n++) { 
              if(allStatements[n].Id === statementId) {
                allStatements[n].messageToUser = message;
                break;
              }
            }
          });
          console.log('...add to processedStatementIds');
          processedStatementIds.push(statementId);
          console.log('...update the state');
          this.setState({ allStatements: allStatements });
        }
      }
     
    }

    addRecord() {
      let allStatements = this.state.allStatements;
      let newStatements = this.state.newStatements;
      let newStatementIds= this.state.newStatementIds;
      let newId = 0;
      let locationId = 0;
      let jobSearchStatusId = 0;
      let displayOrder = 0;

      for(let i=0; i < allStatements.length; i++) {
        if(allStatements[i].Id > newId) newId = allStatements[i].Id;
        if(locationId === 0) locationId = allStatements[i].LocationId;
        if(jobSearchStatusId === 0) jobSearchStatusId = allStatements[i].JobSearchStatusId;
        if(allStatements[i].DisplayOrder > displayOrder) displayOrder = allStatements[i].DisplayOrder;
      }

      let newStatementId = newId + 1;
      let newDisplayOrder = displayOrder + 5;

      let newStatement = { Id: newStatementId, Statement: '', Remote: true, LocationId: locationId, JobSearchStatusId: jobSearchStatusId, DisplayOrder: newDisplayOrder };
      allStatements.push(newStatement);
      newStatements.push(newStatement);
      newStatementIds.push(newStatementId);

      this.setState({ allStatements: allStatements, newStatements: newStatements, newStatementIds: newStatementIds });
    }

    deleteRecord(statementId) {
      let deletedStatementIds = this.state.deletedStatementIds;
      let newStatementIds = this.state.newStatementIds;

      if(newStatementIds.indexOf(statementId) >= 0) {
        let newStatements = this.state.newStatements;
        let updatedList = [];
        for(let i=0; i < newStatementIds.length; i++) { 
          if(newStatementIds[i] !== statementId) {
            updatedList.push(newStatementIds[i]);
          }
        }
        this.setState({ newStatementIds: updatedList });
        for(let i=0; i < newStatements.length; i++) { 
          if(newStatements[i].Id === statementId) {
            newStatements.splice(i, 1);
            break;
          }
        }
        this.setState({ newStatements: newStatements });
        let allStatements = this.state.allStatements;
        let updatedAllStatements = [];
        for(let i=0; i < allStatements.length; i++) {
          if(allStatements[i].Id !== statementId) {
            updatedAllStatements.push(allStatements[i]);
          }
        }
        this.setState({ allStatements: updatedAllStatements });
        return;
      }
      
      if(deletedStatementIds.indexOf(statementId) < 0) {
        deletedStatementIds.push(statementId);
      }
      this.setState({ deletedStatementIds: deletedStatementIds });
    }

    unDeleteRecord(statementId) {
      let deletedStatementIds = this.state.deletedStatementIds;
      let updatedList = [];
      for(let i=0; i < deletedStatementIds.length; i++) { 
        if(deletedStatementIds[i] !== statementId) {
          updatedList.push(deletedStatementIds[i]);
        }
      }
      this.setState({ deletedStatementIds: updatedList });     
    }

    isDeleted(statementId) { 
      let deletedStatementIds = this.state.deletedStatementIds;
      if(deletedStatementIds.indexOf(statementId) >= 0) {
        return true;
      } else {
        return false;
      }
    }

    isUndeleteEnabled(statementId) { 
        return this.state.deletedStatementIds.includes(statementId) ? true : false;
      }

    isDeleteEnabled(statementId) {
    return this.state.deletedStatementIds.includes(statementId) ? false : true;
    }

    isSaveDisabled() {
      if (this.state.editedStatementIds.length === 0 && this.state.deletedStatementIds.length === 0) {
        return true;
      } else {
        return false;
      }
    }

    isSaveEnabled() {  
      if (this.state.editedStatementIds.length > 0 || this.state.deletedStatementIds.length > 0 || this.state.newStatementIds.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    isMoveUpDisabled(update) {
      let smallestDisplayOrder = 0;
      let largestDisplayOrder = 0;
      
      for(let i=0; i < this.state.allStatements.length; i++) {
        if (smallestDisplayOrder === 0) smallestDisplayOrder = this.state.allStatements[i].DisplayOrder;
        if (this.state.allStatements[i].DisplayOrder < smallestDisplayOrder) smallestDisplayOrder = this.state.allStatements[i].DisplayOrder;
        if (largestDisplayOrder === 0) largestDisplayOrder = this.state.allStatements[i].DisplayOrder;
        if (largestDisplayOrder < this.state.allStatements[i].DisplayOrder) largestDisplayOrder = this.state.allStatements[i].DisplayOrder;
      }
      
      return update.DisplayOrder === smallestDisplayOrder;
    }

    isMoveDownDisabled(update) {
      let smallestDisplayOrder = 0;
      let largestDisplayOrder = 0;
      
      for(let i=0; i < this.state.allStatements.length; i++) {
        if (smallestDisplayOrder === 0) smallestDisplayOrder = this.state.allStatements[i].DisplayOrder;
        if (this.state.allStatements[i].DisplayOrder < smallestDisplayOrder) smallestDisplayOrder = this.state.allStatements[i].DisplayOrder;
        if (largestDisplayOrder === 0) largestDisplayOrder = this.state.allStatements[i].DisplayOrder;
        if (largestDisplayOrder < this.state.allStatements[i].DisplayOrder) largestDisplayOrder = this.state.allStatements[i].DisplayOrder;
      }
      
      return largestDisplayOrder <= update.DisplayOrder;
    }

    viewPreviousStatement() {
      this.setState({ selectedStatement: this.state.previousStatement });
      this.setState({ nextStatement: this.state.selectedStatement });
      let previousStatement = this.state.allStatements[this.state.allStatements.indexOf(this.state.previousStatement) - 1];
      this.setState({ previousStatement: previousStatement }); 
    }

    viewNextStatement() {
      this.setState({ previousStatement: this.state.selectedStatement });
      this.setState({ selectedStatement: this.state.nextStatement });
      let nextStatement = this.state.allStatements[this.state.allStatements.indexOf(this.state.nextStatement) + 1];
      if(!nextStatement) nextStatement = null;
      this.setState({ nextStatement: nextStatement });
    }

    componentDidUpdate() {
        if (!this.state.statementsLoaded && this.props.allStatements) {
            this.setState({ allStatements: this.props.allStatements, originalStatementArray: this.props.allStatements, statementsLoaded: true, selectedStatement: this.props.allStatements[0] });
        }
        console.log(this.state);
    }

    componentDidMount() {
      console.log(this.props.allStatements);

        if (!this.state.statementsLoaded && this.props.allStatements) {
            this.setState({ allStatements: this.props.allStatements, originalStatementArray: this.props.allStatements, statementsLoaded: true, selectedStatement: this.props.allStatements[0] });
        }
    }

    render() {   
      let allStatements = this.state.allStatements;
      allStatements.sort((a,b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
        return (
          <div className='app-edit-container'> 
          <div id='editStatementsContainer' className='app-edit-biography-modal-container'> 
            <div className='app-admin-view-edit-section-header'>Edit Biography</div>
            {allStatements && allStatements.map((update, i) => (
              <div key={update.Id + '_container'} className='app-edit-biography-statement-container'>
                <div className='app-edit-biography-statement-ta-container'>
                  <div className='app-edit-biography-statement' key={update.Id}>
                    {this.isDeleted(update.Id) && <div className='app-edit-biography-statement-deleted'>{update.Statement}</div>}
                    {!this.isDeleted(update.Id) && <textarea id={update.Id} className='app-edit-biography-statement-edit' defaultValue={update.Statement} onClick={() => this.selectRecord(update)}  onChange={(e) => this.editRecord(e, update)} />}
                    <div className='app-edit-biography-statement-button-container'>
                    <div className='app-edit-biography-statement-sort-buttons'>
                      <FaSortUp aria-label='move up' title='move up' className={this.isMoveUpDisabled(update) ? 'app-small-icon-disabled' : 'app-small-icon-move-up'} key={i + '_bio_textarea__up'} onClick={() => this.moveUp(update)} />
                      <FaSortDown aria-label='move down' title='move down' className={this.isMoveDownDisabled(update) ? 'app-push-down-50 app-small-icon-disabled' : 'app-push-down-50 app-small-icon-move-down'} key={i + '_bio_textarea__down'} onClick={() => this.moveDown(update) } />
                    </div>
                    <div className='app-edit-biography-statement-delete-buttons '>
                      {this.isDeleted(update.Id) && 
                        <FaTrashArrowUp aria-label='undelete' title='undelete' className='app-push-down-30 app-small-icon-undelete' key={i + '_bio_textarea__delete'} onClick={() => this.unDeleteRecord(update.Id)} />
                      }
                      {!this.isDeleted(update.Id) && 
                        <FaTrash aria-label='delete' title='delete' className='app-push-down-30 app-small-icon-delete' key={i + '_bio_textarea__undelete'} onClick={() => this.deleteRecord(update.Id)} />
                      }
                    </div>
                    <div className='app-edit-biography-statement-save-message-container'>
                      {update.messageToUser}
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            )
            )}
            <div className='app-edit-biography-save-button-container'>
              <FaSquarePlus aria-label='add' title='add' className='app-small-icon-add' id='editStatementsAddButton' onClick={() => this.addRecord()} />
              <FaFloppyDisk aria-label='save' title='save' className={this.isSaveDisabled() ? 'app-small-icon-disabled' : 'app-small-icon-save'} id='editStatementsSaveButton' onClick={() => this.saveRecords()} />
            </div>
          </div>
          </div>
        );
    }
  }


export default Biography;