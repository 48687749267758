import { apiUrl } from '../config.js';
import axios from 'axios';

function AddStatement(payload) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    if (payload.statement !== '') {
        axios({
            method: 'post',
            url: apiUrl + 'addBiography',
            headers: { 'authorization': token, 'Content-Type': 'application/json' },
            data: {
                statement: payload.statement,
                display: payload.display,
                displayOrder: payload.displayOrder,
                locationId: payload.locationId,
                remote: payload.remote,
                statusId: payload.statusId
            }
        })
        .catch(function(error) {
            console.log(error);
            return 'fail';
        })
        .then(function(response) {
            if(response.statusText === 'OK') return 'success';
            else return 'fail';
        })}
    else {
        console.log('you have passed an empty statement - delete instead')
    }
}

export default AddStatement;