import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };



class Biography extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          allStatements: [],
          editedStatements: [],
          editedStatementIds: [],
          deletedStatementIds: [],
          newStatements: [],
          newStatementIds: [],
          originalStatementArray: [],
          showEdit: false,
          subtitle: null,
          modalIsOpen: false,
          statement: '',
          statementsLoaded: false,
      };
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
    }
    openModal() {
      let user = JSON.parse(window.sessionStorage.getItem('user'));
      if(user && user.userId === 1) {
        this.setState({ showEdit: true });
      }
      this.setState({ modalIsOpen: true });
    }
  
    afterOpenModal() {
      this.subtitle.style.color = '#e2e2e2';
    }
  
    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    viewPreviousStatement() {
      this.setState({ selectedStatement: this.state.previousStatement });
      this.setState({ nextStatement: this.state.selectedStatement });
      let previousStatement = this.state.allStatements[this.state.allStatements.indexOf(this.state.previousStatement) - 1];
      this.setState({ previousStatement: previousStatement }); 
    }

    viewNextStatement() {
      this.setState({ previousStatement: this.state.selectedStatement });
      this.setState({ selectedStatement: this.state.nextStatement });
      let nextStatement = this.state.allStatements[this.state.allStatements.indexOf(this.state.nextStatement) + 1];
      if(!nextStatement) nextStatement = null;
      this.setState({ nextStatement: nextStatement });
    }

    componentDidUpdate() {
      if(this.state.reloadStatements) {
        let allStatements = this.props.currentStatusArray.sort((a,b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
        this.setState({ allStatements: allStatements, reloadStatements: false });
      }
    }

    componentDidMount() {
      if(!this.state.statementsLoaded) {
        let allStatements = this.props.currentStatusArray.sort((a,b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
        let selectedStatement = allStatements[0];
        let nextStatement = allStatements[1] ? allStatements[1] : null;

        this.setState({ 
          allStatements: allStatements, 
          statementsLoaded: true, 
          selectedStatement: selectedStatement,
          previousStatement: null,
          nextStatement: nextStatement
        });
      }
    }

    render() {   
      return (
      <div className='app-resume-header-modal-button-container'>
        <button className='app-resume-modal-open-button' onClick={this.openModal}>View Biography</button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Julie McFadden Biography"
        >
          <h2 className='app-resume-bio-header' ref={(this.state.subtitle)}>Biography</h2>
          <button className='app-modal-close-button' onClick={this.closeModal}>close</button>
          
          <div id='biography-statements' className='app-modal-content'>
            {this.state.allStatements && this.state.selectedStatement && (
              <div className='app-biography-selected-statement-container'>
                {this.state.previousStatement && ( <div className='app-biography-previous-statement' onClick={() => this.viewPreviousStatement(this.state.previousStatement)}> back </div> )}
                <div className='app-biography-selected-statement'>{this.state.selectedStatement.Statement}</div> 
                {this.state.nextStatement && ( <div className='app-biography-next-statement' onClick={() => this.viewNextStatement(this.state.nextStatement)}> next </div> )}
              </div>
            )}
            {!this.state.allStatements && <p>Biography has not been loaded - I'm an infant.</p> }
          </div>
        </Modal>
      </div>
    )}
  }


export default Biography;