import React from 'react';
import Chevron from 'react-chevron';
import DatePicker from "react-datepicker";
import { FaFloppyDisk, FaTrash, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import AddProject from '../../../api/Project/addProject.js';
import EditProject from '../../../api/Project/editProject.js';
import DeleteProject from '../../../api/Project/deleteProject.js';

import formatDate from '../../helpers/dateTime.js';

import "react-datepicker/dist/react-datepicker.css";

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allProjects: [],
            editedProjects: [],
            editedProjectIds: [],
            deletedProjectIds: [],
            newProjects: [],
            newProjectIds: [],
            originalProjectArray: [],
            showSave: false,
            projectsLoaded: false,
            selectedProject: null,
            statuses: [],
            employers: [],
            roles: [],                    
        };

      }
      
      addProject() {
        let allProjects = this.state.allProjects;
        let newProjects = this.state.newProjects;
        let newProjectIds = this.state.newProjectIds;
        let newProjectId = 0;
        let startDate = new Date();
        let endDate = null;
        let teamSize = 0;

        if(newProjectIds.length === 0) {
            for(let i=0; i<allProjects.length; i++) {
                if(allProjects[i].Id > newProjectId) newProjectId = allProjects[i].Id;
            }
        } else {
            for(let i=0; i<newProjectIds.length; i++) {
                if(newProjectIds[i] > newProjectId) newProjectId = newProjectIds[i];
            }
        }
        //increment by one to get the new id
        newProjectId++;
        let newProject = { Id: newProjectId, Title: '', Description: '', TeamSize: teamSize, RoleId: 0, EmployerId: 0, StatusId: 0, StartDate: startDate, EndDate: endDate };
        newProjects.push(newProject);
        newProjectIds.push(newProjectId);

        //update the full list with this new one so it can be edited
        allProjects.push(newProject);

        //set the selected Project to the new one for editing and update the state
        this.setState({ allProjects: allProjects, newProjects: newProjects, newProjectIds: newProjectIds, selectedProject: newProject });
      }

      deleteProject(ProjectId) {
        let deletedProjectIds = this.state.deletedProjectIds;
        if (!deletedProjectIds.includes(ProjectId)) {  
            deletedProjectIds.push(ProjectId);
        }
        this.setState({ deletedProjectIds: deletedProjectIds });
      }

      undeleteProject(ProjectId) {
        let deletedProjectIds = this.state.deletedProjectIds;
        for(let i=0; i<deletedProjectIds.length; i++) {
            if(deletedProjectIds[i] === ProjectId) {
                deletedProjectIds.splice(i, 1);
                break;
            }
        }
        this.setState({ deletedProjectIds: deletedProjectIds });
      }

      saveChanges() {
        let edits = this.state.editedProjects;
        let deletes = this.state.deletedProjectIds;
        let adds = this.state.newProjects;
        if (edits.length > 0) {
            console.log('edited projects found....saving ' + edits.length + ' records...', edits);
            for(let i=0; i<edits.length; i++) {
                let payload = { 
                    id: edits[i].Id, 
                    title: edits[i].Title, 
                    description: edits[i].Description,
                    teamSize: edits[i].TeamSize,
                    employerId: edits[i].EmployerId,
                    statusId: edits[i].StatusId,
                    roleId: edits[i].RoleId,
                    startDate: formatDate(new Date(edits[i].StartDate)),
                    endDate: edits[i].EndDate === 'Present' ? null : formatDate(new Date(edits[i].EndDate))
                };
                console.log('payload', payload);
                EditProject(payload);
                console.log('edited project ' + edits[i].Id + ' saved');
            }
        }
        if (deletes.length > 0) {
            console.log('deleted projects found....deleting ' + deletes.length + ' records...');
            for(let i=0; i<deletes.length; i++) {
                let payload = { id: deletes[i] };
                DeleteProject(payload);
            }
        }
        if (adds.length > 0) {
            console.log('new projects found....adding ' + adds.length + ' records...');
            for(let i=0; i<adds.length; i++) {
                let payload = { 
                    id: adds[i].Id,
                    title: edits[i].Title, 
                    description: edits[i].Description,
                    teamSize: edits[i].TeamSize,
                    employerId: edits[i].EmployerId,
                    statusId: edits[i].StatusId,
                    roleId: edits[i].RoleId,
                    startDate: formatDate(new Date(edits[i].StartDate)),
                    endDate: edits[i].EndDate === 'Present' ? null : formatDate(new Date(edits[i].EndDate))
                };
                AddProject(payload);
                console.log('new project ' + adds[i].Id + ' saved');
            }
        }

      }

      isSaveEnabled() {
        return this.state.editedProjectIds.length > 0 || this.state.deletedProjectIds.length > 0 || this.state.newProjectIds.length > 0 ? true : false;  
      }

      isUndeleteEnabled(projectId) { 
        return false;
      }

      isDeleteEnabled(projectId) {
        return false; //for projects we will not allow deletes
      }

      editProjectTitle(project, event) { 
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.Title = event.target.value;
        if(!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }

      editProjectDescription(project, event) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.Description = event.target.value;
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }

      editProjectTeamSize(project, event) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.TeamSize = event.target.value;
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }

      editProjectRole(project, event) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.RoleId = Number(event.target.value);
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }    

      editProjectEmployer(project, event) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.EmployerId = event.target.value;
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }   

      editProjectStatus(project, event) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.StatusId = event.target.value;
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }   

      editProjectStartDate(project, date) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.StartDate = date;
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }

      editProjectEndDate(project, date) {
        let editedProjects = this.state.editedProjects;
        let editedProjectIds = this.state.editedProjectIds;
        project.EndDate = date;
        if (!editedProjectIds.includes(project.Id)) {
            editedProjects.push(project);
            editedProjectIds.push(project.Id);
        } else {
            let projectIndex = editedProjectIds.findIndex(projectId => projectId === project.Id);
            editedProjects[projectIndex] = project;
        }
        this.setState({ editedProjects: editedProjects, editedProjectIds: editedProjectIds });
      }

      previousProject() {
        let thisProjectIndex = this.state.allProjects.findIndex(project => project.Id === this.state.selectedProject.Id);
        let previousProjectIndex = thisProjectIndex - 1;
        if (previousProjectIndex < 0) previousProjectIndex = this.state.allProjects.length - 1;
        let previousProject = this.state.allProjects[previousProjectIndex];
        this.setState({ selectedProject: previousProject });

      }

      nextProject() {
        let thisProjectIndex = this.state.allProjects.findIndex(project => project.Id === this.state.selectedProject.Id);
        let nextProjectIndex = thisProjectIndex + 1;
        if (nextProjectIndex > this.state.allProjects.length - 1) nextProjectIndex = 0;
        let nextProject = this.state.allProjects[nextProjectIndex];
        this.setState({ selectedProject: nextProject });
      }

      componentDidUpdate() {
        if (!this.state.projectsLoaded && this.state.allProjects) {
            this.setState({ 
                allProjects: this.props.allProjects.projects, 
                originalProjectArray: this.props.allProjects.projects, 
                selectedProject: this.props.allProjects.projects[0],
                employers: this.props.allProjects.projectEmployers,
                statuses: this.props.allProjects.projectStatuses,
                roles: this.props.allProjects.projectRoles,
                projectsLoaded: true
             });
        }
      }

      componentDidMount() {
        if (!this.state.projectsLoaded && this.props.allProjects) {
            this.setState({ 
                allProjects: this.props.allProjects.projects, 
                originalProjectArray: this.props.allProjects.projects, 
                selectedProject: this.props.allProjects.projects[0],
                employers: this.props.allProjects.projectEmployers,
                statuses: this.props.allProjects.projectStatuses,
                roles: this.props.allProjects.projectRoles,
                projectsLoaded: true
             });
        }
      }

      render() {
        let projectCount = this.state.allProjects ? this.state.allProjects.length : 0;
        return (
            <div className='app-projects-edit'>
                <div className='app-admin-view-edit-section-header'>Edit Projects</div>
                <div className='app-edit-container'>
                  {this.state.projectsLoaded && this.state.allProjects && this.state.allProjects.length  > 0 && this.state.allProjects.map((project, index) => {
                    return ( this.state.selectedProject && this.state.selectedProject.Id === project.Id ? 
                        <div className='app-admin-view-edit-section-item' key={project.Id}>
                            <div className='app-admin-view-edit-section-item-wrapper'>
                                <div className='app-admin-daterange-container'>
                                    <div className='app-admin-daterange-start'>
                                        <span className='app-admin-daterange-label'>Start:</span>
                                        <span className='app-admin-daterange-picker'><DatePicker selected={new Date(project.StartDate)} onChange={(date) => this.editProjectStartDate(project, date)} /></span>
                                    </div>
                                        {project.EndDate && project.EndDate !== 'Present' && (                                    
                                            <div className='app-admin-daterange-end'>
                                            <span className='app-admin-daterange-label'>End:</span>
                                            <span className='app-admin-daterange-picker'>
                                                <DatePicker selected={new Date(project.EndDate)} onChange={(date) => this.editProjectEndDate(project, date)} />
                                            </span> 
                                        </div>
                                        )}
                                        {project.EndDate && project.EndDate === 'Present' && (                                    
                                            <div className='app-admin-daterange-end'>
                                            <span className='app-admin-daterange-label'>End:</span>
                                            <span className='app-admin-daterange-picker'>
                                                <DatePicker onChange={(date) => this.editProjectEndDate(date)} />
                                            </span> 
                                        </div>
                                        )}
                                </div>
                                <span className='app-admin-view-edit-label'>Title</span>
                                {this.isUndeleteEnabled(project.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-undelete' onClick={() => this.undeleteProject(project.Id)}><span className='app-small-icon-undelete'></span><FaTrashArrowUp /></span>)}
                                {this.isDeleteEnabled(project.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-delete' onClick={() => this.deleteProject(project.Id)}><span className='app-small-icon-delete'></span><FaTrash /></span>)}
                                
                                <input className='app-admin-view-edit-input' defaultValue={project.Title} onChange={(e) => this.editProjectTitle(project, e)} />
                                <span className='app-admin-view-edit-label'>Description</span>
                                <textarea className='app-admin-view-edit-textarea' defaultValue={project.Description} onChange={(e) => this.editProjectDescription(project, e)} />
                                <span className='app-admin-view-edit-label'>Team Size</span>
                                <input  className='app-admin-view-edit-input' defaultValue={project.TeamSize} onChange={(e) => this.editProjectTeamSize(project, e)} />
                                <span className='app-admin-view-edit-label'>Role</span>
                                <select className='app-admin-view-edit-select' defaultValue={project.RoleId} onChange={(e) => this.editProjectRole(project, e) }>
                                    {this.state.roles && this.state.roles.map((role, index) => {
                                        return (
                                            <option key={'role' + role.Id} value={role.Id}>{role.Title}</option>
                                        )
                                    })}
                                </select> 
                                <span className='app-admin-view-edit-label'>Employer</span>
                                <select className='app-admin-view-edit-select' defaultValue={project.EmployerId} onChange={(e) => this.editProjectEmployer(project, e) }>
                                    {this.state.employers && this.state.employers.map((employer, index) => {
                                        return (
                                            <option key={'domain' + employer.Id} value={employer.Id}>{employer.Name}</option>
                                        )
                                    })}
                                </select>

                                <span className='app-admin-view-edit-label'>Status</span>
                                <select className='app-admin-view-edit-select' defaultValue={project.StatusId} onChange={(e) => this.editProjectStatus(project, e) }>
                                    {this.state.statuses && this.state.statuses.map((status, index) => {
                                        return (
                                            <option key={'location' + status.Id} value={status.Id}>{status.Name}</option>
                                        )
                                    })}
                                </select>                                
                            </div>
                            <div className='app-admin-view-edit-section-item-buttons'>
                                <span className={index > 0 ? 'app-admin-view-edit-section-item-button-previous' : 'app-small-icon-disabled'} onClick={() => this.previousProject(project.Id)}><Chevron direction={'left'}  /></span>
                                <span className={index < projectCount ? 'app-admin-view-edit-section-item-button-next' : 'app-small-icon-disabled'} onClick={() => this.nextProject(project.Id)}><Chevron direction={'right'}  /></span>
                            </div>
                        </div>
                        : ''
                    )
                  })}    
                  <div className='app-admin-view-edit-buttons'>
                      <span className='app-small-icon-add' onClick={() => this.addProject()}><span className='app-small-icon-plus'></span><FaSquarePlus /></span>
                      <span className={this.isSaveEnabled() ? 'app-small-icon-save' : 'app-small-icon-disabled'} onClick={() => this.saveChanges()}><span className='app-small-icon-save'></span><FaFloppyDisk /></span>    
                  </div>
                </div>

                
            </div>          
        )
      }

}

export default Projects;