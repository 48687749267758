/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function MonitorModel(props) {
  const groupRef = useRef();
  const { nodes, materials } = useGLTF("/old_school_rgb_computer_monitor.glb");
  return (
    <group ref={groupRef} {...props} dispose={null}>
      <mesh
        geometry={nodes.MonitorBack002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[0, 18, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorBottom002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[0, 0.17999998, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorBrand002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[-0.03449961, 5.87415123, 17.82837486]}
        rotation={[-1.57315289, 0.0016986, -0.00077705]}
        scale={[18, 18, 18.00000381]}
      />
      <mesh
        geometry={nodes.MonitorButtons002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[5.83151293, 2.71210217, 17.32481766]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorFeet003_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[-15.62897587, -0.12590611, 14.9323349]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorKnob003_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[-8.59509659, 2.72216511, 17.50539589]}
        scale={18}
      />
      <mesh
        geometry={nodes.MonitorLatch002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[0, 1.44437027, 17.03166962]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorNumber002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[10.96799088, 5.51540279, 17.65609932]}
        rotation={[-0.37128218, 4e-8, Math.PI / 2]}
        scale={18}
      />
      <mesh
        geometry={nodes.MonitorScreen003_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[0, 19.80000114, -0.2886163]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorShell002_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[0, 18, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
      <mesh
        geometry={nodes.MonitorSwitch003_RetroMonitor001_0.geometry}
        material={materials["RetroMonitor.002"]}
        position={[15.97026634, 5.52122879, 17.39588356]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[18, 17.99999809, 17.99999809]}
      />
    </group>
  );
}

useGLTF.preload("/old_school_rgb_computer_monitor.glb");

export default MonitorModel;