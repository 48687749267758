import axios from 'axios';
import { apiUrl } from '../config.js';

async function Register(payload, callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    if (payload.userName !== '') {
        axios({
            method: 'post',
            url: apiUrl + 'registerUser',
            headers: { 'authorization': token, 'Content-Type': 'application/json' },
            data: {
                userName: payload.userName,
                password: payload.password,
                displayName: payload.displayName,
                role: payload.role
            }
        })
        .catch(function(error) {
            console.log(error);
            callback(error);
        })
        .then(function(response) {
            callback(response);
        })}
    else {
        console.log('you have passed an incomplete payload');
    }    
}

export default Register;