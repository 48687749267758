import React from 'react';

function Help(props) {

    return (
        <span className='app-console-command-list'>
            <p className='app-console-command'>Available commands:</p>
            <p className='app-console-command'> - clear: clear the console</p>
            <p className={props.showRegister ? 'app-console-command' : 'app-hidden'}> - register: register a new user</p>
            <p className={props.showLogin ? 'app-console-command' : 'app-hidden'}> - login: login to the site</p>
            <p className='app-console-command'> - reset: reset your password</p>
            <p className={props.showLogout ? 'app-console-command' : 'app-hidden'}> - logout: logout of the site</p>
            <p className='app-console-command'> - resume: view my resume</p>
            <p className='app-console-command'> - contact: send me a message</p>
            <p className={props.showPlay ? 'app-console-command' : 'app-hidden'}> - play: play a game</p>
            <p className='app-console-command'> - help: show this help text</p>
            <p className='app-console-command'> - ESC: exit the active command</p>
        </span>
    )
}

export default Help;