import React from 'react';
import Chevron from 'react-chevron';
import { FaFloppyDisk, FaTrash, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import AddDomain from '../../../api/Domain/addDomain.js';
import EditDomain from '../../../api/Domain/editDomain.js';
import DeleteDomain from '../../../api/Domain/deleteDomain.js';

class Domains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allDomains: [],
            editedDomains: [],
            editedDomainIds: [],
            deletedDomainIds: [],
            newDomains: [],
            newDomainIds: [],
            originalDomainArray: [],
            showSave: false,
            domainsLoaded: false,
            selectedDomain: null,
        };

      }
      
      addDomain() {
        let allDomains = this.state.allDomains;
        let newDomains = this.state.newDomains;
        let newDomainIds = this.state.newDomainIds;
        let newDomainId = 0;

        if(newDomainIds.length === 0) {
            for(let i=0; i<allDomains.length; i++) {
                if(allDomains[i].Id > newDomainId) newDomainId = allDomains[i].Id;
            }
        } else {
            for(let i=0; i<newDomainIds.length; i++) {
                if(newDomainIds[i] > newDomainId) newDomainId = newDomainIds[i];
            }
        }
        //increment by one to get the new id
        newDomainId++;
        let newDomain = { Id: newDomainId, Name: '', Description: '' };
        newDomains.push(newDomain);
        newDomainIds.push(newDomainId);

        //update the full list with this new one so it can be edited
        allDomains.push(newDomain);

        //set the selected domain to the new one for editing and update the state
        this.setState({ allDomains: allDomains, newDomains: newDomains, newDomainIds: newDomainIds, selectedDomain: newDomain });
      }

      deleteDomain(domainId) {
        let deletedDomainIds = this.state.deletedDomainIds;
        if (!deletedDomainIds.includes(domainId)) {  
            deletedDomainIds.push(domainId);
        }
        this.setState({ deletedDomainIds: deletedDomainIds });
      }

      undeleteDomain(domainId) {
        let deletedDomainIds = this.state.deletedDomainIds;
        for(let i=0; i<deletedDomainIds.length; i++) {
            if(deletedDomainIds[i] === domainId) {
                deletedDomainIds.splice(i, 1);
                break;
            }
        }
        this.setState({ deletedDomainIds: deletedDomainIds });
      }

      saveChanges() {
        let edits = this.state.editedDomains;
        let deletes = this.state.deletedDomainIds;
        let adds = this.state.newDomains;
        if (edits.length > 0) {
            console.log('edited domains found....saving ' + edits.length + ' records...');
            for(let i=0; i<edits.length; i++) {
                let payload = { id: edits[i].Id, name: edits[i].Name, description: edits[i].Description };
                EditDomain(payload);
                console.log('edited domain ' + edits[i].Id + ' saved');
            }
        }
        if (deletes.length > 0) {
            console.log('deleted domains found....deleting ' + deletes.length + ' records...');
            for(let i=0; i<deletes.length; i++) {
                let payload = { id: deletes[i] };
                DeleteDomain(payload);
            }
        }
        if (adds.length > 0) {
            console.log('new domains found....adding ' + adds.length + ' records...');
            for(let i=0; i<adds.length; i++) {
                let payload = { id: adds[i].Id, name: adds[i].Name, description: adds[i].Description };
                AddDomain(payload);
                console.log('new domain ' + adds[i].Id + ' saved');
            }
        }

      }

      isSaveEnabled() {
        return this.state.editedDomainIds.length > 0 || this.state.deletedDomainIds.length > 0 || this.state.newDomainIds.length > 0 ? true : false;  
      }

      isUndeleteEnabled(domainId) { 
        return this.state.deletedDomainIds.includes(domainId) ? true : false;
      }

      isDeleteEnabled(domainId) {
        return this.state.deletedDomainIds.includes(domainId) ? false : true;
      }

      editDomainName(domain, event) { 
        let editedDomains = this.state.editedDomains;
        let editedDomainIds = this.state.editedDomainIds;
        domain.Name = event.target.value;
        if(!editedDomainIds.includes(domain.Id)) {
            editedDomains.push(domain);
            editedDomainIds.push(domain.Id);
        } else {
            let domainIndex = editedDomainIds.findIndex(domainId => domainId === domain.Id);
            editedDomains[domainIndex] = domain;
        }
        this.setState({ editedDomains: editedDomains, editedDomainIds: editedDomainIds });
      }

      editDomainDescription(domain, event) {
        let editedDomains = this.state.editedDomains;
        let editedDomainIds = this.state.editedDomainIds;
        domain.Description = event.target.value;
        if (!editedDomainIds.includes(domain.Id)) {
            editedDomains.push(domain);
            editedDomainIds.push(domain.Id);
        } else {
            let domainIndex = editedDomainIds.findIndex(domainId => domainId === domain.Id);
            editedDomains[domainIndex] = domain;
        }
        this.setState({ editedDomains: editedDomains, editedDomainIds: editedDomainIds });
      }

      previousDomain() {
        let thisDomainIndex = this.state.allDomains.findIndex(domain => domain.Id === this.state.selectedDomain.Id);
        let previousdomainIndex = thisDomainIndex - 1;
        if (previousdomainIndex < 0) previousdomainIndex = this.state.allDomains.length - 1;
        let previousDomain = this.state.allDomains[previousdomainIndex];
        this.setState({ selectedDomain: previousDomain });

      }

      nextDomain() {
        let thisDomainIndex = this.state.allDomains.findIndex(domain => domain.Id === this.state.selectedDomain.Id);
        let nextDomainIndex = thisDomainIndex + 1;
        if (nextDomainIndex > this.state.allDomains.length - 1) nextDomainIndex = 0;
        let nextDomain = this.state.allDomains[nextDomainIndex];
        this.setState({ selectedDomain: nextDomain });
      }

      componentDidUpdate() {
        if (!this.state.domainsLoaded && this.props.allDomains) {
            this.setState({ allDomains: this.props.allDomains, originalDomainArray: this.props.allDomains, domainsLoaded: true, selectedDomain: this.props.allDomains[0] });
        }
      }

      componentDidMount() {
        if (!this.state.domainsLoaded && this.props.allDomains) {
            this.setState({ allDomains: this.props.allDomains, originalDomainArray: this.props.allDomains, domainsLoaded: true, selectedDomain: this.props.allDomains[0] });
        }
      }

      render() {
        let domainCount = this.state.allDomains ? this.state.allDomains.length : 0;
        return (
            <div className='app-modal-edit'>
                <div className='app-admin-view-edit-section-header'>Edit Domains</div>
                <div className='app-edit-container'>
                  {this.state.domainsLoaded && this.state.allDomains && this.state.allDomains.map((domain, index) => {
                    return ( this.state.selectedDomain && this.state.selectedDomain.Id === domain.Id ? 
                        <div className='app-admin-view-edit-section-item' key={domain.Id}>
                            <div className='app-admin-view-edit-section-item-wrapper'>
                                <span className='app-admin-view-edit-label'>Name</span>
                                {this.isUndeleteEnabled(domain.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-undelete' onClick={() => this.undeleteDomain(domain.Id)}><span className='app-small-icon-undelete'></span><FaTrashArrowUp /></span>)}
                                {this.isDeleteEnabled(domain.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-delete' onClick={() => this.deleteDomain(domain.Id)}><span className='app-small-icon-delete'></span><FaTrash /></span>)}
                                
                                <input className={this.isDeleteEnabled(domain.Id) ? 'app-admin-view-edit-input' : 'app-admin-view-edit-input-deleted'} defaultValue={domain.Name} onChange={(e) => this.editDomainName(domain, e)} />
                                <span className='app-admin-view-edit-label'>Description</span>
                                <textarea  className={this.isDeleteEnabled(domain.Id) ? 'app-admin-view-edit-textarea' : 'app-admin-view-edit-textarea-deleted'} defaultValue={domain.Description} onChange={(e) => this.editDomainDescription(domain, e)} />
                            </div>
                            <div className='app-admin-view-edit-section-item-buttons'>
                                <span className={index > 0 ? 'app-admin-view-edit-section-item-button-previous' : 'app-small-icon-disabled'} onClick={() => this.previousDomain(domain.Id)}><Chevron direction={'left'}  /></span>
                                <span className={index < domainCount ? 'app-admin-view-edit-section-item-button-next' : 'app-small-icon-disabled'} onClick={() => this.nextDomain(domain.Id)}><Chevron direction={'right'}  /></span>
                            </div>
                        </div>
                        : ''
                    )
                  })}    
                  <div className='app-admin-view-edit-buttons'>
                      <span className='app-small-icon-add' onClick={() => this.addDomain()}><span className='app-small-icon-plus'></span><FaSquarePlus /></span>
                      <span className={this.isSaveEnabled() ? 'app-small-icon-save' : 'app-small-icon-disabled'} onClick={() => this.saveChanges()}><span className='app-small-icon-save'></span><FaFloppyDisk /></span>    
                  </div>
                </div>

                
            </div>          
        )
      }

}

export default Domains;