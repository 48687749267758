import axios from 'axios';
import { apiUrl } from '../config.js';

function DeleteEmployer(payload) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    axios({
        method: 'post',
        url: apiUrl + 'deleteEmployer',
        headers: { 'authorization': token, 'Content-Type': 'application/json' },
        data: {
            id: payload.id,
        }
    })
    .catch(function(error) {
        console.log(error);
        return 'fail';
    })
    .then(function(response) {
        if(response.statusText === 'OK') return 'success';
        else return 'fail';
    })
}

export default DeleteEmployer;