const allowedKeyCodes = [
    8, // Backspace
    13, // Enter
    16, // Shift
    27, // Escape
    32, // Space
    190, // Period
    110, // Numpad period
    186, // Semicolon
    109, // Numpad minus
    189, // Minus
    191, // Question mark
    188, // Comma
    222, // Single quote
    50, // @
    48, // 0
    49, // 1
    50, // 2
    51, // 3
    52, // 4
    53, // 5
    54, // 6
    55, // 7
    56, // 8
    57, // 9
    65, // A
    66, // B
    67, // C
    68, // D
    69, // E
    70, // F
    71, // G
    72, // H
    73, // I
    74, // J
    75, // K
    76, // L
    77, // M
    78, // N
    79, // O
    80, // P
    81, // Q
    82, // R
    83, // S
    84, // T
    85, // U
    86, // V
    87, // W
    88, // X
    89, // Y
    90, // Z
];

const specialKeyCodes = [
    8, // Backspace
    13, // Enter
    16, // Shift
    27, // Escape  
    191, // Question mark
]

const alphaNumericsAndSymbols = [
    32, // Space
    190, // Period
    110, // Numpad period
    186, // Semicolon
    109, // Numpad minus
    189, // Minus
    191, // Question mark
    188, // Comma
    222, // Single quote
    50, // @
    48, // 0
    49, // 1
    50, // 2
    51, // 3
    52, // 4
    53, // 5
    54, // 6
    55, // 7
    56, // 8
    57, // 9
    65, // A
    66, // B
    67, // C
    68, // D
    69, // E
    70, // F
    71, // G
    72, // H
    73, // I
    74, // J
    75, // K
    76, // L
    77, // M
    78, // N
    79, // O
    80, // P
    81, // Q
    82, // R
    83, // S
    84, // T
    85, // U
    86, // V
    87, // W
    88, // X
    89, // Y
    90, // Z
];

const AllKeyCodes = { allowedKeyCodes, specialKeyCodes, alphaNumericsAndSymbols};

export default AllKeyCodes;