import React, { useState }  from 'react';
import useMeasure from 'react-use-measure';

function PowerButton(props) {
    const [buttonRef] = useMeasure();
    const [stageHeight] = useState(props.stageHeight);
    const [stageWidth] = useState(props.stageWidth);

    const buttonStyle = {
        position: 'absolute',
        top: Math.floor(stageHeight * 0.5) + 107,
        left: Math.floor(stageWidth * 0.58) -2
    };

    function togglePower() {
        console.log(props);
        if(props.powerStatus === 'off') {
            props.powerOnHandler();
        } else {
            props.powerOffHandler();
        }
    }

    return (
        <div id='vrPowerButton' onClick={() => togglePower()} ref={buttonRef} style={buttonStyle} className={props.powerStatus === 'off' ? 'app-vr-power-button' : props.powerStatus === 'on' ? 'app-vr-power-button-on' : 'app-vr-power-button-powering'} /> 
    );
}

export default PowerButton;