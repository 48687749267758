import React from 'react';

class Playground extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          allGames: [ { id: 1,  name: 'rochambeau' }, { id: 2, name: 'tic-tac-toe' }, { id: 3, name: 'sudoku' } ],
          selectedGame: null,
      };
    }

    componentDidUpdate() {
        if (this.state.selectedGame === null && this.props.gameId !== null) {
            this.setState({ selectedGame: { id: this.props.gameId, name: '' } });
        }

        if (this.state.selectedGame && this.state.selectedGame.id !== this.props.gameId) {
            this.setState({ selectedGame: { id: this.props.gameId, name: '' } });
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className='app-playground-container'>
                <div className='app-playground-header'>
                    {this.state.allGames && this.state.allGames.map((game, index) => {
                        return (
                            <div className='app-playground-header-item' key={index}>
                                {game.id} - {game.name}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

}

export default Playground;