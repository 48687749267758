import React from 'react';
import Chevron from 'react-chevron';
import DatePicker from "react-datepicker";
import { FaFloppyDisk, FaTrash, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import AddEmployer from '../../../api/Employer/addEmployer.js';
import EditEmployer from '../../../api/Employer/editEmployer.js';
import DeleteEmployer from '../../../api/Employer/deleteEmployer.js';


import "react-datepicker/dist/react-datepicker.css";

class Employers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allEmployers: [],
            editedEmployers: [],
            editedEmployerIds: [],
            deletedEmployerIds: [],
            newEmployers: [],
            newEmployerIds: [],
            originalEmployerArray: [],
            showSave: false,
            employersLoaded: false,
            selectedEmployer: null,
            locations: [],
            domains: [],
            roles: [],                    
        };

      }
      
      addEmployer() {
        let allEmployers = this.state.allEmployers;
        let newEmployers = this.state.newEmployers;
        let newEmployerIds = this.state.newEmployerIds;
        let newEmployerId = 0;
        let startDate = new Date();
        let endDate = null;

        if(newEmployerIds.length === 0) {
            for(let i=0; i<allEmployers.length; i++) {
                if(allEmployers[i].Id > newEmployerId) newEmployerId = allEmployers[i].Id;
            }
        } else {
            for(let i=0; i<newEmployerIds.length; i++) {
                if(newEmployerIds[i] > newEmployerId) newEmployerId = newEmployerIds[i];
            }
        }
        //increment by one to get the new id
        newEmployerId++;
        let newEmployer = { Id: newEmployerId, Name: '', Description: '', DetailedDescription: '', DomainId: 0, LocationId: 0, RoleId: 0, StartDate: startDate, EndDate: endDate };
        newEmployers.push(newEmployer);
        newEmployerIds.push(newEmployerId);

        //update the full list with this new one so it can be edited
        allEmployers.push(newEmployer);

        //set the selected Employer to the new one for editing and update the state
        this.setState({ allEmployers: allEmployers, newEmployers: newEmployers, newEmployerIds: newEmployerIds, selectedEmployer: newEmployer });
      }

      deleteEmployer(EmployerId) {
        let deletedEmployerIds = this.state.deletedEmployerIds;
        if (!deletedEmployerIds.includes(EmployerId)) {  
            deletedEmployerIds.push(EmployerId);
        }
        this.setState({ deletedEmployerIds: deletedEmployerIds });
      }

      undeleteEmployer(EmployerId) {
        let deletedEmployerIds = this.state.deletedEmployerIds;
        for(let i=0; i<deletedEmployerIds.length; i++) {
            if(deletedEmployerIds[i] === EmployerId) {
                deletedEmployerIds.splice(i, 1);
                break;
            }
        }
        this.setState({ deletedEmployerIds: deletedEmployerIds });
      }

      saveChanges() {
        let edits = this.state.editedEmployers;
        let deletes = this.state.deletedEmployerIds;
        let adds = this.state.newEmployers;
        if (edits.length > 0) {
            console.log('edited employers found....saving ' + edits.length + ' records...', edits);
            for(let i=0; i<edits.length; i++) {
                let payload = { 
                    id: edits[i].Id, 
                    name: edits[i].Name, 
                    description: edits[i].Description,
                    detailedDescription: edits[i].DetailedDescription,
                    domainId: edits[i].DomainId,
                    locationId: edits[i].LocationId,
                    roleId: edits[i].RoleId,
                    startDate: edits[i].StartDate,
                    endDate: edits[i].EndDate
                };
                EditEmployer(payload);
                console.log('edited employer ' + edits[i].Id + ' saved');
            }
        }
        if (deletes.length > 0) {
            console.log('deleted employers found....deleting ' + deletes.length + ' records...');
            for(let i=0; i<deletes.length; i++) {
                let payload = { id: deletes[i] };
                DeleteEmployer(payload);
            }
        }
        if (adds.length > 0) {
            console.log('new employers found....adding ' + adds.length + ' records...');
            for(let i=0; i<adds.length; i++) {
                let payload = { 
                    id: adds[i].Id,
                    name: edits[i].Name, 
                    description: edits[i].Description,
                    detailedDescription: edits[i].DetailedDescription,
                    domainId: edits[i].DomainId,
                    locationId: edits[i].LocationId,
                    roleId: edits[i].RoleId,
                    startDate: edits[i].StartDate,
                    endDate: edits[i].EndDate                };
                AddEmployer(payload);
                console.log('new employer ' + adds[i].Id + ' saved');
            }
        }

      }

      isSaveEnabled() {
        return this.state.editedEmployerIds.length > 0 || this.state.deletedEmployerIds.length > 0 || this.state.newEmployerIds.length > 0 ? true : false;  
      }

      isUndeleteEnabled(employerId) { 
        return false;
      }

      isDeleteEnabled(employerId) {
        return false; //for employers we will not allow deletes
      }

      editEmployerName(employer, event) { 
        let editedEmployers = this.state.editedEmployers;
        let editedEmployerIds = this.state.editedEmployerIds;
        employer.Title = event.target.value;
        if(!editedEmployerIds.includes(employer.Id)) {
            editedEmployers.push(employer);
            editedEmployerIds.push(employer.Id);
        } else {
            let employerIndex = editedEmployerIds.findIndex(employerId => employerId === employer.Id);
            editedEmployers[employerIndex] = employer;
        }
        this.setState({ editedEmployers: editedEmployers, editedEmployerIds: editedEmployerIds });
      }

      editEmployerDescription(employer, event) {
        let editedEmployers = this.state.editedEmployers;
        let editedEmployerIds = this.state.editedEmployerIds;
        employer.Description = event.target.value;
        if (!editedEmployerIds.includes(employer.Id)) {
            editedEmployers.push(employer);
            editedEmployerIds.push(employer.Id);
        } else {
            let employerIndex = editedEmployerIds.findIndex(employerId => employerId === employer.Id);
            editedEmployers[employerIndex] = employer;
        }
        this.setState({ editedEmployers: editedEmployers, editedEmployerIds: editedEmployerIds });
      }

      editEmployerDetailedDescription(employer, event) {
        let editedEmployers = this.state.editedEmployers;
        let editedEmployerIds = this.state.editedEmployerIds;
        employer.DetailedDescription = event.target.value;
        if (!editedEmployerIds.includes(employer.Id)) {
            editedEmployers.push(employer);
            editedEmployerIds.push(employer.Id);
        } else {
            let employerIndex = editedEmployerIds.findIndex(employerId => employerId === employer.Id);
            editedEmployers[employerIndex] = employer;
        }
        this.setState({ editedEmployers: editedEmployers, editedEmployerIds: editedEmployerIds });
      }

      editEmployerRole(employer, event) {
        let editedEmployers = this.state.editedEmployers;
        let editedEmployerIds = this.state.editedEmployerIds;
        employer.RoleId = Number(event.target.value);
        if (!editedEmployerIds.includes(employer.Id)) {
            editedEmployers.push(employer);
            editedEmployerIds.push(employer.Id);
        } else {
            let employerIndex = editedEmployerIds.findIndex(employerId => employerId === employer.Id);
            editedEmployers[employerIndex] = employer;
        }
        this.setState({ editedEmployers: editedEmployers, editedEmployerIds: editedEmployerIds });
      }    

      editEmployerDomain(employer, event) {
        let editedEmployers = this.state.editedEmployers;
        let editedEmployerIds = this.state.editedEmployerIds;
        employer.DomainId = event.target.value;
        if (!editedEmployerIds.includes(employer.Id)) {
            editedEmployers.push(employer);
            editedEmployerIds.push(employer.Id);
        } else {
            let employerIndex = editedEmployerIds.findIndex(employerId => employerId === employer.Id);
            editedEmployers[employerIndex] = employer;
        }
        this.setState({ editedEmployers: editedEmployers, editedEmployerIds: editedEmployerIds });
      }   

      editEmployerLocation(employer, event) {
        let editedEmployers = this.state.editedEmployers;
        let editedEmployerIds = this.state.editedEmployerIds;
        employer.LocationId = event.target.value;
        if (!editedEmployerIds.includes(employer.Id)) {
            editedEmployers.push(employer);
            editedEmployerIds.push(employer.Id);
        } else {
            let employerIndex = editedEmployerIds.findIndex(employerId => employerId === employer.Id);
            editedEmployers[employerIndex] = employer;
        }
        this.setState({ editedEmployers: editedEmployers, editedEmployerIds: editedEmployerIds });
      }   

      editEmployerStartDate(date) {
        console.log(date);
      }

      editEmployerEndDate(date) {
        console.log(date);
      }

      previousEmployer() {
        let thisEmployerIndex = this.state.allEmployers.findIndex(employer => employer.Id === this.state.selectedEmployer.Id);
        let previousEmployerIndex = thisEmployerIndex - 1;
        if (previousEmployerIndex < 0) previousEmployerIndex = this.state.allEmployers.length - 1;
        let previousEmployer = this.state.allEmployers[previousEmployerIndex];
        this.setState({ selectedEmployer: previousEmployer });

      }

      nextEmployer() {
        let thisEmployerIndex = this.state.allEmployers.findIndex(employer => employer.Id === this.state.selectedEmployer.Id);
        let nextEmployerIndex = thisEmployerIndex + 1;
        if (nextEmployerIndex > this.state.allEmployers.length - 1) nextEmployerIndex = 0;
        let nextEmployer = this.state.allEmployers[nextEmployerIndex];
        this.setState({ selectedEmployer: nextEmployer });
      }

      componentDidUpdate() {
        if (!this.state.employersLoaded && this.props.allEmployers) {
            this.setState({ allEmployers: this.props.allEmployers, originalEmployerArray: this.props.allEmployers, employersLoaded: true, selectedEmployer: this.props.allEmployers[0] });
        }
      }

      componentDidMount() {
        if (!this.state.employersLoaded && this.props.allEmployers) {
            console.log(this.props.allEmployers);
            this.setState({ 
                allEmployers: this.props.allEmployers.employers, 
                originalEmployerArray: this.props.allEmployers.employers, 
                employersLoaded: true, 
                selectedEmployer: this.props.allEmployers.employers[0],
                locations: this.props.allEmployers.locations,
                domains: this.props.allEmployers.domains,
                roles: this.props.allEmployers.roles
             });
        }
      }

      render() {
        let employerCount = this.state.allEmployers ? this.state.allEmployers.length : 0;
        return (
            <div className='app-employers-edit'>
                <div className='app-admin-view-edit-section-header'>Edit Employers</div>
                <div className='app-edit-container'>
                  {this.state.employersLoaded && this.state.allEmployers && this.state.allEmployers.map((employer, index) => {
                    return ( this.state.selectedEmployer && this.state.selectedEmployer.Id === employer.Id ? 
                        <div className='app-admin-view-edit-section-item' key={employer.Id}>
                            <div className='app-admin-view-edit-section-item-wrapper'>
                                <div className='app-admin-daterange-container'>
                                    <div className='app-admin-daterange-start'>
                                        <span className='app-admin-daterange-label'>Start:</span>
                                        <span className='app-admin-daterange-picker'><DatePicker selected={new Date(employer.StartDate)} onChange={(date) => this.editEmployerStartDate(date)} /></span>
                                    </div>
                                        {employer.EndDate && (                                    
                                            <div className='app-admin-daterange-end'>
                                            <span className='app-admin-daterange-label'>End:</span>
                                            <span className='app-admin-daterange-picker'>
                                                <DatePicker selected={new Date(employer.EndDate)} onChange={(date) => this.editEmployerEndDate(date)} />
                                            </span> 
                                        </div>
                                        )}
                                </div>
                                <span className='app-admin-view-edit-label'>Name</span>
                                {this.isUndeleteEnabled(employer.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-undelete' onClick={() => this.undeleteEmployer(employer.Id)}><span className='app-small-icon-undelete'></span><FaTrashArrowUp /></span>)}
                                {this.isDeleteEnabled(employer.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-delete' onClick={() => this.deleteEmployer(employer.Id)}><span className='app-small-icon-delete'></span><FaTrash /></span>)}
                                
                                <input className='app-admin-view-edit-input' defaultValue={employer.Name} onChange={(e) => this.editEmployerName(employer, e)} />
                                <span className='app-admin-view-edit-label'>Description</span>
                                <input className='app-admin-view-edit-input' defaultValue={employer.Description} onChange={(e) => this.editEmployerDescription(employer, e)} />
                                <textarea  className='app-admin-view-edit-textarea' defaultValue={employer.DetailedDescription} onChange={(e) => this.editEmployerDetailedDescription(employer, e)} />
                                <span className='app-admin-view-edit-label'>Role</span>
                                <select className='app-admin-view-edit-select' defaultValue={employer.RoleId} onChange={(e) => this.editEmployerRole(employer, e) }>
                                    {this.state.roles && this.state.roles.map((role, index) => {
                                        return (
                                            <option key={'role' + role.Id} value={role.Id}>{role.Title}</option>
                                        )
                                    })}
                                </select> 
                                <span className='app-admin-view-edit-label'>Domain</span>
                                <select className='app-admin-view-edit-select' defaultValue={employer.DomainId} onChange={(e) => this.editEmployerDomain(employer, e) }>
                                    {this.state.domains && this.state.domains.map((domain, index) => {
                                        return (
                                            <option key={'domain' + domain.Id} value={domain.Id}>{domain.Name}</option>
                                        )
                                    })}
                                </select>

                                <span className='app-admin-view-edit-label'>Location</span>
                                <select className='app-admin-view-edit-select' defaultValue={employer.LocationId} onChange={(e) => this.editEmployerLocation(employer, e) }>
                                    {this.state.locations && this.state.locations.map((location, index) => {
                                        return (
                                            <option key={'location' + location.Id} value={location.Id}>{location.City}, {location.State}</option>
                                        )
                                    })}
                                </select>                                
                            </div>
                            <div className='app-admin-view-edit-section-item-buttons'>
                                <span className={index > 0 ? 'app-admin-view-edit-section-item-button-previous' : 'app-small-icon-disabled'} onClick={() => this.previousEmployer(employer.Id)}><Chevron direction={'left'}  /></span>
                                <span className={index < employerCount ? 'app-admin-view-edit-section-item-button-next' : 'app-small-icon-disabled'} onClick={() => this.nextEmployer(employer.Id)}><Chevron direction={'right'}  /></span>
                            </div>
                        </div>
                        : ''
                    )
                  })}    
                  <div className='app-admin-view-edit-buttons'>
                      <span className='app-small-icon-add' onClick={() => this.addEmployer()}><span className='app-small-icon-plus'></span><FaSquarePlus /></span>
                      <span className={this.isSaveEnabled() ? 'app-small-icon-save' : 'app-small-icon-disabled'} onClick={() => this.saveChanges()}><span className='app-small-icon-save'></span><FaFloppyDisk /></span>    
                  </div>
                </div>

                
            </div>          
        )
      }

}

export default Employers;