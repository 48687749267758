import { Tooltip } from 'react-tooltip'
import React from 'react';
import Modal from 'react-modal';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const mappings = [
    { name: 'Javascript', icon: 'devicon-javascript-plain', layer: 'front' },
    { name: 'jQuery', icon: 'devicon-jquery-plain', layer: 'front' },
    { name: 'CSS', icon: 'devicon-css3-plain', layer: 'front' },
    { name: 'C#', icon: 'devicon-csharp-plain', layer: 'middle' },
    { name: 'Git', icon: 'devicon-github-original', layer: 'process' },
    { name: 'VSCode', icon: 'devicon-vscode-plain', layer: 'front' },
    { name: 'SQL', icon: 'devicon-microsoftsqlserver-plain', layer: 'back' },
    { name: 'React', icon: 'devicon-react-original', layer: 'front' },
    { name: 'HTML5', icon: 'devicon-html5-plain', layer: 'front' },
    { name: 'Visual Studio', icon: 'devicon-visualstudio-plain', layer: 'middle' },
    { name: 'Angular', icon: 'devicon-angularjs-plain', layer: 'front' },
    { name: 'Java', icon: 'devicon-java-plain', layer: 'back' },
    { name: 'Agile', icon: 'devicon-devicon-plain', layer: 'process' },
    { name: 'Node.JS', icon: 'devicon-nodejs-plain', layer: 'middle' },
    { name: 'NPM', icon: 'devicon-npm-original-wordmark', layer: 'process' },
    { name: 'Perl', icon: 'devicon-perl-plain', layer: 'back' },
    { name: 'Redux', icon: 'devicon-redux-original', layer: 'front' },
    { name: 'Typescript', icon: 'devicon-typescript-plain', layer: 'front' }     
];

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


function SkillSets(passedArray) {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
 
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#e2e2e2';
    }
  
    function closeModal() {
      setIsOpen(false);
    }


    let theseSkills = [];
    let skillSet = passedArray.skillSets;
    for(let i = 0; i < skillSet.length; i++) {
        let skillSetName = skillSet[i].name;
        for(let n = 0; n < mappings.length; n++) {
            if(skillSetName === mappings[n].name) {
                let thisSkill = { 
                    name: skillSet[i].name, 
                    usage: skillSet[i].usage,
                    icon: mappings[n].icon, 
                    layer: mappings[n].layer
                };
                theseSkills.push(thisSkill);
            }
        }
    }

    const skillsRendered = theseSkills.map((skillSet, i) => {
        let toolTipContent = skillSet.usage ? skillSet.usage : 'less than a year';
        let elementId = '_skillSet_' + Math.random().toString();
        return  (
            <div  key={i + '_' + skillSet.name + '_icon'} className='app-resume-skillset-icons-icon'>
                <i id={elementId} data-tooltip-content={toolTipContent} className={'app-skillsets-item app-skillsets-item--' + skillSet.layer + ' ' + skillSet.icon }></i>
                <Tooltip anchorId={elementId} />

                <span className='app-resume-skillset-icons-icon-text'>{skillSet.name}</span>
            </div>

        )
    });

    const buttonLabel = 'skills used';

    return (
        <div className='app-resume-header-modal-button-container'>
        <button className='app-resume-modal-open-button' onClick={openModal}>{buttonLabel}</button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel={buttonLabel}
        >
          <h2 className='app-resume-bio-header' ref={(_subtitle) => (subtitle = _subtitle)}>Skillsets</h2>
          <button className='app-modal-close-button' onClick={closeModal}>close</button>
          <div className='app-resume-skillset-icons'>
            {skillsRendered}
          </div>
        </Modal>
      </div>

    )
}

export default SkillSets;