import axios from 'axios';
import { apiUrl } from '../config.js';

async function Login(payload, callback) {
    axios.post(apiUrl + 'login', {
        userName: payload.userName,
        password: payload.password
      })
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        callback(error.response);
      });

}

export default Login;