import { apiUrl } from '../config.js';
import axios from 'axios';

function GetDomains(callback) {
    axios({
        method: 'get',
        url: apiUrl + 'getDomains',
        headers: { 'Content-Type': 'application/json' },
    })
    .catch(function(error) {
        console.log(error);
        return 'fail';
    })
    .then(function(response) {
        if(response.statusText === 'OK') callback(response.data);
        else return 'fail'; 
    })
}

export default GetDomains;