import React from 'react';
import Chevron from 'react-chevron';
import { FaFloppyDisk, FaTrash, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import AddStatus from '../../../api/Status/addStatus.js';
import EditStatus from '../../../api/Status/editStatus.js';
import DeleteStatus from '../../../api/Status/deleteStatus.js';

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allStatuses: [],
            editedStatuses: [],
            editedStatusIds: [],
            deletedStatusIds: [],
            newStatuses: [],
            newStatusIds: [],
            originalStatusArray: [],
            showSave: false,
            statusesLoaded: false,
            selectedStatus: null,
        };

      }
      
      addStatus() {
        let allStatuses = this.state.allStatuses;
        let newStatuses = this.state.newStatuses;
        let newStatusIds = this.state.newStatusIds;
        let newStatusId = 0;

        if(newStatusIds.length === 0) {
            for(let i=0; i<allStatuses.length; i++) {
                if(allStatuses[i].Id > newStatusId) newStatusId = allStatuses[i].Id;
            }
        } else {
            for(let i=0; i<newStatusIds.length; i++) {
                if(newStatusIds[i] > newStatusId) newStatusId = newStatusIds[i];
            }
        }
        //increment by one to get the new id
        newStatusId++;
        let newStatus = { Id: newStatusId, Title: '', Description: '' };
        newStatuses.push(newStatus);
        newStatusIds.push(newStatusId);

        //update the full list with this new one so it can be edited
        allStatuses.push(newStatus);

        //set the selected Status to the new one for editing and update the state
        this.setState({ allStatuses: allStatuses, newStatuses: newStatuses, newStatusIds: newStatusIds, selectedStatus: newStatus });
      }

      deleteStatus(StatusId) {
        let deletedStatusIds = this.state.deletedStatusIds;
        if (!deletedStatusIds.includes(StatusId)) {  
            deletedStatusIds.push(StatusId);
        }
        this.setState({ deletedStatusIds: deletedStatusIds });
      }

      undeleteStatus(StatusId) {
        let deletedStatusIds = this.state.deletedStatusIds;
        for(let i=0; i<deletedStatusIds.length; i++) {
            if(deletedStatusIds[i] === StatusId) {
                deletedStatusIds.splice(i, 1);
                break;
            }
        }
        this.setState({ deletedStatusIds: deletedStatusIds });
      }

      saveChanges() {
        let edits = this.state.editedStatuses;
        let deletes = this.state.deletedStatusIds;
        let adds = this.state.newStatuses;
        if (edits.length > 0) {
            console.log('edited Statuses found....saving ' + edits.length + ' records...');
            for(let i=0; i<edits.length; i++) {
                let payload = { id: edits[i].Id, name: edits[i].Name, description: edits[i].Description, setAsCurrent: edits[i].SetAsCurrent };
                EditStatus(payload);
                console.log('edited Status ' + edits[i].Id + ' saved');
            }
        }
        if (deletes.length > 0) {
            console.log('deleted Statuses found....deleting ' + deletes.length + ' records...');
            for(let i=0; i<deletes.length; i++) {
                let payload = { id: deletes[i] };
                DeleteStatus(payload);
            }
        }
        if (adds.length > 0) {
            console.log('new Statuses found....adding ' + adds.length + ' records...');
            for(let i=0; i<adds.length; i++) {
                let payload = { id: adds[i].Id, name: adds[i].Name, description: adds[i].Description, setAsCurrent: edits[i].SetAsCurrent };
                AddStatus(payload);
                console.log('new Status ' + adds[i].Id + ' saved');
            }
        }

      }

      isSaveEnabled() {
        return this.state.editedStatusIds.length > 0 || this.state.deletedStatusIds.length > 0 || this.state.newStatusIds.length > 0 ? true : false;  
      }

      isUndeleteEnabled(StatusId) { 
        return this.state.deletedStatusIds.includes(StatusId) ? true : false;
      }

      isDeleteEnabled(StatusId) {
        return this.state.deletedStatusIds.includes(StatusId) ? false : true;
      }

      editStatusName(status, event) { 
        let editedStatuses = this.state.editedStatuses;
        let editedStatusIds = this.state.editedStatusIds;
        status.Name = event.target.value;
        if(!editedStatusIds.includes(status.Id)) {
            editedStatuses.push(status);
            editedStatusIds.push(status.Id);
        } else {
            let StatusIndex = editedStatusIds.findIndex(StatusId => StatusId === status.Id);
            editedStatuses[StatusIndex] = status;
        }
        this.setState({ editedStatuses: editedStatuses, editedStatusIds: editedStatusIds });
      }

      editStatusDescription(status, event) {
        let editedStatuses = this.state.editedStatuses;
        let editedStatusIds = this.state.editedStatusIds;
        status.Description = event.target.value;
        if (!editedStatusIds.includes(status.Id)) {
            editedStatuses.push(status);
            editedStatusIds.push(status.Id);
        } else {
            let StatusIndex = editedStatusIds.findIndex(StatusId => StatusId === status.Id);
            editedStatuses[StatusIndex] = status;
        }
        this.setState({ editedStatuses: editedStatuses, editedStatusIds: editedStatusIds });
      }

      editStatusCurrent(status) {
        let editedStatuses = this.state.editedStatuses;
        let editedStatusIds = this.state.editedStatusIds;
        if (status.SetAsCurrent) status.SetAsCurrent = false;
        else status.SetAsCurrent = true;

        if (!editedStatusIds.includes(status.Id)) {
            editedStatuses.push(status);
            editedStatusIds.push(status.Id);
        } else {
            let StatusIndex = editedStatusIds.findIndex(StatusId => StatusId === status.Id);
            editedStatuses[StatusIndex] = status;
        }
        this.setState({ editedStatuses: editedStatuses, editedStatusIds: editedStatusIds });
      }

      previousStatus() {
        let thisStatusIndex = this.state.allStatuses.findIndex(Status => Status.Id === this.state.selectedStatus.Id);
        let previousStatusIndex = thisStatusIndex - 1;
        if (previousStatusIndex < 0) previousStatusIndex = this.state.allStatuses.length - 1;
        let previousStatus = this.state.allStatuses[previousStatusIndex];
        this.setState({ selectedStatus: previousStatus });

      }

      nextStatus() {
        let thisStatusIndex = this.state.allStatuses.findIndex(Status => Status.Id === this.state.selectedStatus.Id);
        let nextStatusIndex = thisStatusIndex + 1;
        if (nextStatusIndex > this.state.allStatuses.length - 1) nextStatusIndex = 0;
        let nextStatus = this.state.allStatuses[nextStatusIndex];
        this.setState({ selectedStatus: nextStatus });
      }

      componentDidUpdate() {
        if (!this.state.statusesLoaded && this.props.allStatuses) {
            this.setState({ allStatuses: this.props.allStatuses, originalStatusArray: this.props.allStatuses, statusesLoaded: true, selectedStatus: this.props.allStatuses[0] });
        }
      }

      componentDidMount() {
        if (!this.state.statusesLoaded && this.props.allStatuses) {
            this.setState({ allStatuses: this.props.allStatuses, originalStatusArray: this.props.allStatuses, statusesLoaded: true, selectedStatus: this.props.allStatuses[0] });
        }
      }

      render() {
        let statusCount = this.state.allStatuses ? this.state.allStatuses.length : 0;
        return (
            <div className='app-roles-edit'>
                <div className='app-admin-view-edit-section-header'>Edit Statuses</div>
                <div className='app-edit-container'>
                  {this.state.statusesLoaded && this.state.allStatuses && this.state.allStatuses.map((status, index) => {
                    return ( this.state.selectedStatus && this.state.selectedStatus.Id === status.Id ? 
                        <div className='app-admin-view-edit-section-item' key={status.Id}>
                            <div className='app-admin-view-edit-section-item-wrapper'>
                                <span className='app-admin-view-edit-label'>Name</span>
                                <span onClick={() => this.editStatusCurrent(status)} className={status.SetAsCurrent ? 'app-admin-view-edit-label-check-selected' : 'app-admin-view-edit-label-check'}> </span>
                                {this.isUndeleteEnabled(status.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-undelete' onClick={() => this.undeleteStatus(status.Id)}><span className='app-small-icon-undelete'></span><FaTrashArrowUp /></span>)}
                                {this.isDeleteEnabled(status.Id) && (<span className='app-float-right app-push-left-70 app-small-icon-delete' onClick={() => this.deleteStatus(status.Id)}><span className='app-small-icon-delete'></span><FaTrash /></span>)}
                                
                                <input className={this.isDeleteEnabled(status.Id) ? 'app-admin-view-edit-input' : 'app-admin-view-edit-input-deleted'} defaultValue={status.Name} onChange={(e) => this.editStatusName(status, e)} />
                                <span className='app-admin-view-edit-label'>Description</span>
                                <textarea  className={this.isDeleteEnabled(status.Id) ? 'app-admin-view-edit-textarea' : 'app-admin-view-edit-textarea-deleted'} defaultValue={status.Description} onChange={(e) => this.editStatusDescription(status, e)} />
                            </div>
                            <div className='app-admin-view-edit-section-item-buttons'>
                                <span className={index > 0 ? 'app-admin-view-edit-section-item-button-previous' : 'app-small-icon-disabled'} onClick={() => this.previousStatus(status.Id)}><Chevron direction={'left'}  /></span>
                                <span className={index < statusCount ? 'app-admin-view-edit-section-item-button-next' : 'app-small-icon-disabled'} onClick={() => this.nextStatus(status.Id)}><Chevron direction={'right'}  /></span>
                            </div>
                        </div>
                        : ''
                    )
                  })}    
                  <div className='app-admin-view-edit-buttons'>
                      <span className='app-small-icon-add' onClick={() => this.addStatus()}><span className='app-small-icon-plus'></span><FaSquarePlus /></span>
                      <span className={this.isSaveEnabled() ? 'app-small-icon-save' : 'app-small-icon-disabled'} onClick={() => this.saveChanges()}><span className='app-small-icon-save'></span><FaFloppyDisk /></span>    
                  </div>
                </div>

                
            </div>          
        )
      }

}

export default Status;