import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
        };
    }
    
    componentDidUpdate() {
        if (!this.props.isLoading && this.state.showLoader) {
            this.setState({ showLoader: false });
        }
    }

    componentDidMount() {
        if(this.props.isLoading && !this.state.showLoader) {
            this.setState({ showLoader: true });
        }
    }

    render() {
        return (
            <div className={this.state.showLoader ? 'app-loader' : 'app-hidden'}>
                <div className='app-loader-icon'>
                    <ThreeCircles ariaLabel='loading' visible={this.props.isLoading} />
                 </div>
            </div>
        );
    }

}

export default Loader;