import { apiUrl } from '../config.js';
import axios from 'axios';

function AllUpdates(callback) {
    axios({
        method: 'get',
        url: apiUrl + 'getUpdates',
        headers: { 'Content-Type': 'application/json' },
    })
    .catch(function(error) {
        console.log(error);
        callback({status: 'fail', data: error});
    })
    .then(function(response) {
        callback({status: response.statusText, data: response.data}) 
    })
}

export default AllUpdates;