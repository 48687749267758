import { apiUrl } from '../config.js';
import axios from 'axios';

function AddJournalEntry(payload, callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    if (payload.sectionText !== '') {
        axios({
            method: 'post',
            url: apiUrl + 'addJournal',
            headers: { 'authorization': token, 'Content-Type': 'application/json' },
            data: {
                isPrivate: payload.isPrivate
            }
        })
        .catch(function(error) {
            console.log(error);
            callback(error);
        })
        .then(function(response) {
            callback(response.data[0][0]);
        })}
    else {
        console.log('you have passed an empty section - delete instead')
    }
}

export default AddJournalEntry;