function formatDate(dateValue, includeTime = false, displayOnly = false) {
    if(dateValue) {

        const date = new Date(dateValue);

        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2,'0');
        const dd = String(date.getDate()).padStart(2,'0');

        if(includeTime && !displayOnly) {
            const hh = String(date.getHours()).padStart(2,'0');
            const mi = String(date.getMinutes()).padStart(2,'0');
            const ss = String(date.getSeconds()).padStart(2,'0');
            return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`
        }

        if(includeTime && displayOnly) {
            const hh = String(date.getHours() % 12).padStart(2,'0');
            const mi = String(date.getMinutes()).padStart(2,'0');
            const ampm = date.getHours() > 12 ? 'pm' : 'am';
            return `${mm}-${dd}-${yyyy} ${hh}:${mi} ${ampm}`
        }

        if(!includeTime && displayOnly) return `${mm}-${dd}-${yyyy}`

        return `${yyyy}-${mm}-${dd}`
    } else {
        return '';
    }
}

export default formatDate;