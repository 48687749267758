import React, { Suspense, useState, useEffect, useRef  }  from 'react'
import { Canvas } from '@react-three/fiber'
import { Html } from '@react-three/drei'

import MonitorModel from './components/VR/MonitorModel'
import VRCommandPrompt from './components/Console/vrCommandPrompt.js';

function VrView(props) {
    const [commandText, setCommandText] = useState('');
    const [commandPrompt, setCommandPrompt] = useState('enter command');
    const [commandHistory, setCommandHistory] = useState([]);
    const [activeCommand, setActiveCommand] = useState('');
    const [invalidInputEntry, setInvalidInputEntry] = useState(false);
    const [redirectCommands, setRedirectCommands] = useState(['resume', 'vr', 'exit']);
    const [secureRedirectCommands, setSecureRedirectCommands] = useState(['admin', 'journal']);
    const [secureCommands, setSecureCommands] = useState(['register']);
    const [user, setUser] = useState({ userName: '', password: '', action: 'login', role: '', displayName: '' });
    const [powerStatus, setPowerStatus] = useState('off');
    const [poweredOn, setPoweredOn] = useState(false);
    const [poweringOn, setPoweringOn] = useState(false);
    const [poweringOff, setPoweringOff] = useState(false);
    const [bannerTextLine1, setBannerTextLine1] = useState('');
    const [bannerTextLine2, setBannerTextLine2] = useState('');
    const [bannerTextLine3, setBannerTextLine3] = useState('');
    const [shutDownText, setShutDownText] = useState('');
    const [bannerLoaded, setBannerLoaded] = useState(false);
    const [stageHeight, setHeight] = useState(0);
    const [stageWidth, setWidth] = useState(0);

    const loggedInUser = JSON.parse(window.sessionStorage.getItem('user'));
    const stageCanvasRef = useRef(null);

    useEffect(() => {
        setRedirectCommands(['resume', 'vr', 'exit', 'quit']);
        setSecureRedirectCommands(['admin', 'journal']);
        setSecureCommands(['register']);
        setPowerStatus('off');
        setBannerTextLine1('');
        setBannerTextLine2('');
        setBannerTextLine3('');
        setBannerLoaded(false);
        if(stageCanvasRef.current) {
            let height = stageCanvasRef.current.clientHeight;
            let width = stageCanvasRef.current.clientWidth;
            setHeight(height);  
            setWidth(width);
            console.log(stageCanvasRef.current.clientHeight);
        }
    }, [stageCanvasRef]);

    const redirectHandler = (redirectTo) => {
        if(redirectTo === 'exit' || redirectTo === 'quit') { document.location = "/"; }
        else { document.location = "/" + redirectTo }
    }



    return (
        <div className="app-vr" id="scene-container" ref={stageCanvasRef} >
            <Canvas camera={{ position: [0,0,150] }  } >
                <directionalLight intensity={5} position={[3.3, 1.0, 4.4]}/>
                <Suspense fallback={null}>
                    <MonitorModel scale={[3.5, 3.5, 3.5]} position={[0, -70, 0]}/>
                    <Html position={[-70, 65, 0]}>
                        <VRCommandPrompt 
                            stageHeight = {stageHeight}
                            stageWidth = {stageWidth}
                            commandPrompt = {commandPrompt} 
                            commandText = {commandText} 
                            commandHistory = {commandHistory} 
                            invalidInputEntry = {invalidInputEntry}
                            activeCommand = {activeCommand}
                            setCommandText = {setCommandText}
                            setCommandHistory = {setCommandHistory}
                            setCommandPrompt = {setCommandPrompt}      
                            setInvalidInputEntry = {setInvalidInputEntry}    
                            setActiveCommand = {setActiveCommand}    
                            redirectCommands = {redirectCommands}
                            secureRedirectCommands = {secureRedirectCommands}
                            secureCommands = {secureCommands}
                            redirectHandler = {redirectHandler} 
                            loggedInUser = {loggedInUser}
                            user = {user}
                            setUser = {setUser}
                            powerStatus = {powerStatus}
                            setPowerStatus = {setPowerStatus}
                            poweredOn = {poweredOn}
                            setPoweredOn = {setPoweredOn}
                            poweringOn = {poweringOn}
                            setPoweringOn = {setPoweringOn}
                            poweringOff = {poweringOff}
                            setPoweringOff = {setPoweringOff}
                            bannerTextLine1 = {bannerTextLine1}
                            setBannerTextLine1 = {setBannerTextLine1}
                            bannerTextLine2 = {bannerTextLine2}
                            setBannerTextLine2 = {setBannerTextLine2}
                            bannerTextLine3 = {bannerTextLine3}
                            setBannerTextLine3 = {setBannerTextLine3}     
                            bannderLoaded = {bannerLoaded}
                            setBannerLoaded = {setBannerLoaded}   
                            shutDownText = {shutDownText}
                            setShutDownText = {setShutDownText}                    
                        /> 
                    </Html>
                </Suspense>
            </Canvas>
            
        </div>
    )
}

export default VrView;