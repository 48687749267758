import React from 'react';
import { FaFloppyDisk, FaTrash, FaSortUp, FaSortDown, FaTrashArrowUp, FaSquarePlus } from 'react-icons/fa6';

import AddJournalSection from '../../../api/Journal/addJournalSection.js';
import AddJournalEntry from '../../../api/Journal/addJournalEntry.js';
import DeleteJournalEntry from '../../../api/Journal/deleteJournalEntry.js';

import formatDate from '../../helpers/dateTime.js';

class Journal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          allJournalEntries: [],
          editedJournalRecords: [],
          editedJournalIds: [],
          deletedJournalIds: [],
          editedJournalSectionIds: [],
          deletedJournalSectionIds: [],
          newJournalIds: [],
          newJournalEntryIds: [],
          newJournalEntries: [],
          originalJournalEntryArray: [],
          sectionText: '',
          journalLoaded: false,
          selectedJournal: null,
          
        };

    }

    move(direction, section) {
        let thisDisplayOrder = section.displayOrder;
        let myNewDisplayOrder = thisDisplayOrder - 1;

        let selectedJournal = this.state.selectedJournal;
        let selectedJournalSections = selectedJournal.sections;
        let editedJournalIds = this.state.editedJournalIds;
        let editedJournalEntries = this.state.editedJournalRecords;
        
        //make sure we've sorted the sections by display order
        selectedJournalSections.sort((a, b) => a.displayOrder < b.displayOrder ? 1 : -1)
        //now go through all of the sections and find the previous entry
        //set the one above to this one's display order value
        for(let i=0; i < selectedJournalSections.length; i++) {
            if (selectedJournalSections[i].displayOrder < thisDisplayOrder && direction === 'up') {
                myNewDisplayOrder = selectedJournalSections[i].displayOrder;
                selectedJournalSections[i].displayOrder = thisDisplayOrder;
                break; 
            }
            if (selectedJournalSections[i].displayOrder > thisDisplayOrder && direction === 'down') {
                myNewDisplayOrder = selectedJournalSections[i].displayOrder;
                selectedJournalSections[i].displayOrder = thisDisplayOrder;
                break; 
            }
        }
        //now update this one to use the new display order
        for(let i=0; i < selectedJournalSections.length; i++) {
            if(selectedJournalSections[i].sectionId === section.sectionId) {
                selectedJournalSections[i].displayOrder = myNewDisplayOrder;
                break;
            }
        }
        selectedJournal.sections = selectedJournalSections;
        //now update the state objects for saving
        if(editedJournalIds.indexOf(section.journalId) >= 0) {
            for(let i=0; i<editedJournalEntries.length; i++) {
                if (editedJournalEntries[i].journalId === section.journalId) {
                    for(let n=0; n<editedJournalEntries[i].sections.length; n++) {
                        if(editedJournalEntries[i].sections[n].sectionId === section.sectionId) {
                            editedJournalEntries[i].sections[n].displayOrder = myNewDisplayOrder;
                            break;
                        }
                    }
                }
            }   
        } else {
            //add it to the editedJournalIds
            editedJournalIds.push(selectedJournal.journalId);
            editedJournalEntries.push(selectedJournal);
        }
        
      //now update this one in the allsectionTexts list
      let allJournalEntries = this.state.allJournalEntries;
      for(let i=0; i<allJournalEntries.length; i++) {
        if(allJournalEntries[i].journalId === section.journalId) {
          allJournalEntries[i].sections = selectedJournalSections;
          break;
        }
      }

      //and now update the state
      this.setState({ allJournalEntries: allJournalEntries, editedJournalEntries: editedJournalEntries, editedJournalIds: editedJournalIds, selectedJournal: selectedJournal });
      
    }

    editSection(event, section) {
        let sectionText = event.target.value;
        let editedJournalIds = this.state.editedJournalIds;
        let editedJournalRecords = this.state.editedJournalRecords;
        let thisJournal = this.state.selectedJournal;

        //if this journal is already in the editedJournalEntries array, update the sectionText
        if(editedJournalIds.indexOf(thisJournal.journalId) >= 0) {
            for(let i=0; i<editedJournalRecords.length; i++) {
                if(editedJournalRecords[i].journalId === thisJournal.journalId) {
                for(let n=0; n<editedJournalRecords[i].sections.length; n++) {
                        if(editedJournalRecords[i].sections[n].sectionId === section.sectionId) {
                        editedJournalRecords[i].sections[n].sectionText = sectionText;
                        break;
                        }
                    }
                }
            }
        } else {
            //update the sectionText in the journal object
            for(let i=0; i< thisJournal.sections.length; i++) {
              if(thisJournal.sections[i].sectionId === section.sectionId) {
                thisJournal.sections[i].sectionText = sectionText;
                break;
              }
            }
            //add the journal to the editedJournalEntries array
            editedJournalRecords.push(thisJournal);
            //add the journal id to the edited array
            editedJournalIds.push(thisJournal.journalId);
        }
        //now update the state
        this.setState({ selectedJournal: thisJournal, editedJournalRecords: editedJournalRecords, editedJournalIds: editedJournalIds });

    }

    saveJournal(updateType, journalId, callback) {
      let allJournalEntries = this.state.allJournalEntries;
      if(updateType === 'delete') {
        let that = this;
        let payload = { id: journalId };
        DeleteJournalEntry(payload, function(response) {
          if(response.statusText === 'OK') {
            //remove it from the state list to get it removed from display
            let updatedList = [];
            for(let i=0; i< allJournalEntries.length; i++) {
              if(allJournalEntries[i].journalId !== journalId) {
                updatedList.push(allJournalEntries[i]);
              }
            }
            that.setState({ allJournalEntries: updatedList });
          }
          callback(response);
        });
      }
      if(updateType === 'edit') {
        //we can add and edit journal sections here
      }
      if(updateType === 'new') {
        //load up the payload
        let newJournalEntries = this.state.newJournalEntries;
        let thisJournalEntry = {};
        for(let i=0; i<newJournalEntries.length; i++) {
          if(newJournalEntries[i].journalId === journalId) {
            thisJournalEntry = newJournalEntries[i];
            break;
          }
        };
        //create the parent record
        let payload = { isPrivate: false };
        let that = this;
        AddJournalEntry(payload, function(response) {
          let newJournalId = response.JournalId;
          console.log('newJournalId: ', newJournalId);
          //update the state object
          for(let i=0; i<allJournalEntries.length; i++) {
            if(allJournalEntries[i].journalId === journalId) {
              allJournalEntries[i].journalId = newJournalId;
              break;
            }
          }
          for(let i=0; i<newJournalEntries.length; i++) {
            if(newJournalEntries[i].journalId === journalId) {
              newJournalEntries[i].journalId = newJournalId;
              break;
            } 
          }
          //create the child (section) records
          thisJournalEntry.journalId = newJournalId;
          for(let i=0; i<thisJournalEntry.sections.length; i++) {
            thisJournalEntry.sections[i].journalId = newJournalId;
            let payload = { journalId: newJournalId, sectionText: thisJournalEntry.sections[i].sectionText, displayOrder: thisJournalEntry.sections[i].displayOrder };  
            console.log('...adding new section to new journal...');
            AddJournalSection(payload, function(response) {
              console.log(response);
            });
          }
        
          that.setState({ allJournalEntries: allJournalEntries, newJournalEntries: newJournalEntries });
          callback(response);
        });

        console.log(newJournalEntries);
        console.log(journalId);
      }
    }

    saveRecords() {
      let allJournalEntries = this.state.allJournalEntries;
      //handle parent records first
      let newJournalEntries = this.state.newJournalEntries;

      //this will create the parent and save the children
      for(let i=0; i<newJournalEntries.length; i++) {
        console.log('...adding journal id: ', newJournalEntries[i].journalId);
        this.saveJournal('new', newJournalEntries[i].journalId, function(response) {
          console.log(response.JournalId + ' saved...');
        });
      }

      let deletedJournalIds = this.state.deletedJournalIds;
      let deletedJournalSectionIds = this.state.deletedJournalSectionIds; 

      let updatedDeletedJournalSectionIds = [];
      
      for(let i=0; i<deletedJournalIds.length; i++) {
        //we remove all sections with the parent record, so update the section delete list and clean them out
        for(let n=0; n<allJournalEntries.length; n++) {
          if(allJournalEntries[n].journalId === deletedJournalIds[i]) {
            for(let s=0; s<allJournalEntries[n].sections.length; s++) {
              if(deletedJournalSectionIds.indexOf(allJournalEntries[n].sections[s].sectionId) < 0) {
                updatedDeletedJournalSectionIds.push(allJournalEntries[n].sections[s].sectionId);
              }
            }
          } else {
            for(let s=0; s<allJournalEntries[n].sections.length; s++) {
              if(deletedJournalSectionIds.indexOf(allJournalEntries[n].sections[s].sectionId) >= 0) {
                updatedDeletedJournalSectionIds.push(allJournalEntries[n].sections[s].sectionId);
              }
            }
          }
        }
        console.log('...deleting journal id: ', deletedJournalIds[i]);
        this.saveJournal('delete', deletedJournalIds[i], function(response) {
          console.log(response);
        });
      }

      deletedJournalSectionIds = updatedDeletedJournalSectionIds;
      this.setState({ deletedJournalSectionIds: deletedJournalSectionIds });


      for(let i=0; i<deletedJournalSectionIds.length; i++) {
        console.log('...deleting section id: ', deletedJournalSectionIds[i]);
      }
    
    }

    addJournalRecord() {
        let allJournalEntries = this.state.allJournalEntries;
        let newJournalEntries = this.state.newJournalIds;
        let newJournalEntryIds= this.state.newJournalEntryIds;
        let newId = 0;

        //get the next id
        for(let i=0; i < allJournalEntries.length; i++) {
            if(allJournalEntries[i].journalId > newId) newId = allJournalEntries[i].journalId;
        }

        let newJournalId = newId + 1;
        let newJournalName = new Date();
        let newJournal = { journalId: newJournalId, journalName: newJournalName, sections: [ {sectionId: 0, sectionText: '', displayOrder: 1 }] };


        allJournalEntries.push(newJournal);
        newJournalEntries.push(newJournal);
        newJournalEntryIds.push(newJournal);

        this.setState({ selectedJournal: newJournal, allJournalEntries: allJournalEntries, newJournalEntries: newJournalEntries, newJournalEntryIds: newJournalEntryIds });
    }

    addSectionRecord() {
      let selectedJournal = this.state.selectedJournal;
      selectedJournal.sections.sort((a, b) => a.displayOrder < b.displayOrder ? 1 : -1);
      let newSectionId = 100;
      let newSectionText = '';
      let newSectionDisplayOrder = 10000; //throw it at the end
      if(selectedJournal.sections.length > 0) {
        newSectionId = (selectedJournal.sections[0].sectionId + 1) * 100;  //hack to make sure it displays - unlikely to ever have more than 100 sectiosn per journal
      }
      let newSection = { sectionId: newSectionId, sectionText: newSectionText, displayOrder: newSectionDisplayOrder };
      selectedJournal.sections.push(newSection);
      let editedJournalIds = this.state.editedJournalIds;
      let editedJournalEntries = this.state.editedJournalRecords;
      if(editedJournalIds.indexOf(selectedJournal.journalId) < 0) {
        editedJournalIds.push(selectedJournal.journalId);
        editedJournalEntries.push(selectedJournal);
      }
      this.setState({ selectedJournal: selectedJournal, editedJournalIds: editedJournalIds, editedJournalRecords: editedJournalEntries });
    }

    deleteJournalRecord(journalId) {
        let deletedJournalIds = this.state.deletedJournalIds;
        let newJournalIds = this.state.newJournalIds;
        //check if this is a new one -- if so, just remove it from the state entirely
        if(newJournalIds.indexOf(journalId) >= 0) {
            newJournalIds.slice(newJournalIds.indexOf(journalId), 1);
            this.setState({ newJournalIds: newJournalIds });

            let newList = this.state.newJournalEntries;
            let updatedList = [];
            for(let i=0; i< newList.length; i++) {
                if(newList[i].journalId !== journalId) {
                    updatedList.push(newList[i]);
                }
            }
            this.setState({ newJournalEntries: updatedList });
            return;
        }

        //if this one has been edited, remove it from the edited list
        let editedJournalIds = this.state.editedJournalIds;
        if(editedJournalIds.indexOf(journalId) >= 0) {
            editedJournalIds.slice(editedJournalIds.indexOf(journalId), 1);
            this.setState({ editedJournalIds: editedJournalIds });
            let editedList = this.state.editedJournalRecords;
            let updatedList = [];
            for(let i=0; i < editedList.length; i++) {
                if(editedList[i].journalId !== journalId) {
                    updatedList.push(editedList[i]);
                }
            }
            this.setState({ editedJournalRecords: updatedList });
            return;
        }

        //now add it to the deleted list
        if(deletedJournalIds.indexOf(journalId) < 0) {
            deletedJournalIds.push(journalId);
            //and delete all the sections
            let deletedJournalSectionIds = this.state.deletedJournalSectionIds;
            let allJournalEntries = this.state.allJournalEntries;
            for(let i=0; i<allJournalEntries.length; i++) {
                if(allJournalEntries[i].journalId === journalId) {
                    for(let n=0; n<allJournalEntries[i].sections.length; n++) {
                        deletedJournalSectionIds.push(allJournalEntries[i].sections[n].sectionId);
                    }
                }
            }
            this.setState({ deletedJournalSectionIds: deletedJournalSectionIds });
        }
        this.setState({ deletedJournalIds: deletedJournalIds });
    }

    deleteJournalSection(sectionId) {
      let deletedJournalSectionIds = this.state.deletedJournalSectionIds;
      let newJournalEntryIds = this.state.newJournalEntryIds;

      if(newJournalEntryIds.indexOf(sectionId) >= 0) {
        let newJournalEntries = this.state.newJournalIds;
        let updatedList = [];
        for(let i=0; i < newJournalEntryIds.length; i++) { 
          if(newJournalEntryIds[i] !== sectionId) {
            updatedList.push(newJournalEntryIds[i]);
          }
        }
        this.setState({ newJournalEntryIds: updatedList });
        for(let i=0; i < newJournalEntries.length; i++) { 
          if(newJournalEntries[i].Id === sectionId) {
            newJournalEntries.splice(i, 1);
            break;
          }
        }
        this.setState({ newJournalEntries: newJournalEntries });
        let allJournalEntries = this.state.allJournalEntries;
        let updatedAllStatements = [];
        for(let i=0; i < allJournalEntries.length; i++) {
          if(allJournalEntries[i].Id !== sectionId) {
            updatedAllStatements.push(allJournalEntries[i]);
          }
        }
        this.setState({ allJournalEntries: updatedAllStatements });
        return;
      }
      
      if(deletedJournalSectionIds.indexOf(sectionId) < 0) {
        deletedJournalSectionIds.push(sectionId);
      }
      this.setState({ deletedJournalSectionIds: deletedJournalSectionIds });
    }

    unDeleteSectionRecord(journalId, sectionId) {
      let deletedJournalSectionIds = this.state.deletedJournalSectionIds;
      let updatedList = [];
      for(let i=0; i < deletedJournalSectionIds.length; i++) { 
        if(deletedJournalSectionIds[i] !== sectionId) {
          updatedList.push(deletedJournalSectionIds[i]);
        }
      }
      this.setState({ deletedJournalSectionIds: updatedList });  
      //now make sure we haven't deleted the whole journal entry   
      let deletedJournalIds = this.state.deletedJournalIds;
      if(deletedJournalIds.indexOf(journalId) >= 0) {
        deletedJournalIds.slice(deletedJournalIds.indexOf(journalId), 1);
        this.setState({ deletedJournalIds: deletedJournalIds });
      }
    }

    unDeleteJournalRecord(journalId) {
        let deletedJournalIds = this.state.deletedJournalIds;
        let updatedList = [];
        for(let i=0; i < deletedJournalIds.length; i++) { 
          if(deletedJournalIds[i] !== journalId) {
            updatedList.push(deletedJournalIds[i]);
          }
        }
        let deletedJournalSectionIds = this.state.deletedJournalSectionIds;
        let thisJournalSectionIds = [];
        let allJournalEntries = this.state.allJournalEntries;

        //get the sectionids we need to undelete
        for(let i=0; i < allJournalEntries.length; i++) {
            if(allJournalEntries[i].journalId === journalId) {
                for(let n=0; n < allJournalEntries[i].sections.length; n++) {
                    thisJournalSectionIds.push(allJournalEntries[i].sections[n].sectionId);
                }
                break;
            }
        }

        //now remove them from the deletedJournalSectionIds
        let updatedDeletedSectionsList = [];
        for(let i=0; i < deletedJournalSectionIds.length; i++) {
            if(thisJournalSectionIds.indexOf(deletedJournalSectionIds[i]) < 0) {
                updatedDeletedSectionsList.push(deletedJournalSectionIds[i]);
            }
        }
        
        this.setState({ deletedJournalIds: updatedList, deletedJournalSectionIds: updatedDeletedSectionsList });
    }

    isJournalDeleted(journalId) {
        let deletedJournalIds = this.state.deletedJournalIds;
        if(deletedJournalIds.indexOf(journalId) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    isDeleted(sectionId) { 
      let deletedJournalSectionIds = this.state.deletedJournalSectionIds;
      if(deletedJournalSectionIds.indexOf(sectionId) >= 0) {
        return true;
      } else {
        return false;
      }
    }

    isUndeleteEnabled(sectionId) { 
        return this.state.deletedJournalSectionIds.includes(sectionId) ? true : false;
      }

    isDeleteEnabled(sectionId) {
    return this.state.deletedJournalSectionIds.includes(sectionId) ? false : true;
    }

    isSaveDisabled() {
      if (this.state.editedJournalIds.length === 0 && this.state.editedJournalSectionIds.length === 0 && this.state.deletedJournalSectionIds.length === 0) {
        return true;
      } else {
        return false;
      }
    }

    isSaveEnabled() {  
      if (this.state.editedJournalIds.length > 0 || this.state.editedJournalSectionIds.length > 0 || this.state.deletedJournalSectionIds.length > 0 || this.state.newJournalEntryIds.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    isMoveUpDisabled(section) {
      let smallestDisplayOrder = 0;
      let largestDisplayOrder = 0;
      
      for(let i=0; i < this.state.selectedJournal.sections.length; i++) {
        if (smallestDisplayOrder === 0) smallestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
        if (this.state.selectedJournal.sections[i].displayOrder < smallestDisplayOrder) smallestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
        if (largestDisplayOrder === 0) largestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
        if (largestDisplayOrder < this.state.selectedJournal.sections[i].displayOrder) largestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
      }
      
      return section.displayOrder === smallestDisplayOrder;
    }

    isMoveDownDisabled(section) {
      let smallestDisplayOrder = 0;
      let largestDisplayOrder = 0;
      
      for(let i=0; i < this.state.selectedJournal.sections.length; i++) {
        if (smallestDisplayOrder === 0) smallestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
        if (this.state.selectedJournal.sections[i].displayOrder < smallestDisplayOrder) smallestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
        if (largestDisplayOrder === 0) largestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
        if (largestDisplayOrder < this.state.selectedJournal.sections[i].displayOrder) largestDisplayOrder = this.state.selectedJournal.sections[i].displayOrder;
      }
      
      return largestDisplayOrder <= section.displayOrder;
    }


    selectJournal(journal) {
        this.setState({ selectedJournal: journal });
    }

    selectSection(section) {
        console.log(section);
        console.log(this.state.selectedJournal);
    }

    componentDidUpdate() {
        if (!this.state.journalLoaded && this.props.allJournalEntries) {
            this.setState({ allJournalEntries: this.props.allEntries, originalJournalEntryArray: this.props.allEntries, journalLoaded: true, selectedJournal: this.props.allEntries[0] });
        }
    }

    componentDidMount() {
        if (!this.state.journalLoaded && this.props.allEntries) {
            this.setState({ allJournalEntries: this.props.allEntries, originalJournalEntryArray: this.props.allEntries, journalLoaded: true, selectedJournal: this.props.allEntries[0] });
        }
    }

    render() {   
      let allJournalEntries = this.state.allJournalEntries;
      allJournalEntries.sort((a,b) => a.journalName > b.journalName ? 1 : -1);
        return (
          <div className='app-edit-container'> 
            <div className='app-admin-list-container'>
                <div className='app-admin-list-header'>
                    <span className='app-admin-list-header-label'>Julie's Journals</span>
                    <span className='app-admin-list-header-buttons'>
                        <FaSquarePlus aria-label='add journal entry' title='add journal entry' className='app-small-icon-add' id='editStatementsAddButton' onClick={() => this.addJournalRecord()} />
                    </span>
                </div>
                {allJournalEntries && allJournalEntries.map((journal, i) => (
                    <div key={i + '_journal'} className={journal.journalId === this.state.selectedJournal.journalId ? 'app-admin-list-item--selected' : 'app-admin-list-item'}>
                        <div className={this.isJournalDeleted(journal.journalId) ? 'app-admin-list-item-name--deleted' : 'app-admin-list-item-name'} onClick={() => this.selectJournal(journal)}>{formatDate(journal.journalName)}</div>
                        {this.isJournalDeleted(journal.journalId) && (
                            <div className='app-admin-list-item-buttons'>
                                <FaTrashArrowUp aria-label='undelete journal entry' title='undelete journal entry' className='app-small-icon-undelete' key={i + '_textarea__delete'} onClick={() => this.unDeleteJournalRecord(journal.journalId)} />
                            </div>
                        )}
                        {!this.isJournalDeleted(journal.journalId) && (
                            <div className='app-admin-list-item-buttons'>
                                <FaTrash aria-label='delete journal entry' title='delete journal entry' className='app-small-icon-delete' key={i + '_textarea__undelete'} onClick={() => this.deleteJournalRecord(journal.journalId)} />
                            </div>                            
                        )}

                    </div>
                ))}
            </div>
            {this.state.selectedJournal && (
                <div className='app-admin-selected-item-container'>
                    <div className='app-admin-selected-item-header'>Journal Entry for {formatDate(this.state.selectedJournal.journalName)}</div>
                        {this.state.selectedJournal && this.state.selectedJournal.sections && this.state.selectedJournal.sections.map((section, i) => (
                            <div key={section.sectionId}>
                                {this.isDeleted(section.sectionId) && <textarea id={section.sectionId} className='app-admin-textarea--deleted' defaultValue={section.sectionText} /> }
                                {!this.isDeleted(section.sectionId) && <textarea id={section.sectionId} className='app-admin-textarea--edit' defaultValue={section.sectionText} onClick={() => this.selectSection(section)}  onChange={(e) => this.editSection(e, section)} />}
                                <div className='app-admin-textarea-sort-buttons'>
                                    <FaSortUp aria-label='move up' title='move up' className={this.isMoveUpDisabled(section) ? 'app-small-icon-disabled' : 'app-small-icon-move-up'} key={i + '_bio_textarea__up'} onClick={() => this.move('up',  section)} />
                                    <FaSortDown aria-label='move down' title='move down' className={this.isMoveDownDisabled(section) ? 'app-push-down-50 app-small-icon-disabled' : 'app-push-down-50 app-small-icon-move-down'} key={i + '_bio_textarea__down'} onClick={() => this.move('down', section) } />
                                </div>
                                <div className='app-admin-textarea-delete-buttons'>
                                    {this.isDeleted(section.sectionId) && 
                                        <FaTrashArrowUp aria-label='undelete section' title='undelete section' className='app-push-down-30 app-small-icon-undelete' key={i + '_textarea__delete'} onClick={() => this.unDeleteSectionRecord(section.journalId, section.sectionId)} />
                                    }
                                    {!this.isDeleted(section.sectionId) && 
                                        <FaTrash aria-label='delete section' title='delete section' className='app-push-down-30 app-small-icon-delete' key={i + '_textarea__undelete'} onClick={() => this.deleteJournalSection(section.sectionId)} />
                                    }
                                </div>
                            </div>
                        ))}
                    <div className='app-admin-button-container-nested'>
                        <FaSquarePlus aria-label='add section' title='add section' className='app-small-icon-add' id='editStatementsAddButton' onClick={() => this.addSectionRecord()} />
                    </div>                   
                </div>
            )}
            <div className='app-admin-button-container'>
                <FaFloppyDisk aria-label='save' title='save' className={this.isSaveDisabled() ? 'app-small-icon-disabled' : 'app-small-icon-save'} id='editStatementsSaveButton' onClick={() => this.saveRecords()} />
            </div>
          </div>
        );
    }
  }


export default Journal;