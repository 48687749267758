import React from 'react';

function CommandHistory(props){
    return (
        <span className='app-console-command-list'>
            {props.history && props.history.map((command, index) => {
                return (
                    <p className='app-console-command' key={index}> - {command}</p>
                )})
            }
        </span>
    )
}

export default CommandHistory;