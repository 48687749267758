import { apiUrl } from '../config.js';
import axios from 'axios';

function GetUsers(callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    axios({
        method: 'get',
        url: apiUrl + 'getUsers',
        headers: { 'authorization': token, 'Content-Type': 'application/json' },
    })
    .catch(function(error) {
        console.log(error);
        callback(error);
    })
    .then(function(response) {
        callback(response.data);
    })
}

export default GetUsers;