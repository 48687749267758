import { apiUrl } from '../config.js';
import axios from 'axios';

function AddJournalSection(payload, callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    if (payload.sectionText !== '') {
        axios({
            method: 'post',
            url: apiUrl + 'addJournalSection',
            headers: { 'authorization': token, 'Content-Type': 'application/json' },
            data: {
                journalId: payload.journalId,
                sectionText: payload.sectionText,
                displayOrder: payload.displayOrder
            }
        })
        .catch(function(error) {
            console.log(error);
            callback(error);
        })
        .then(function(response) {
            console.log(response);
            callback(response);
        })}
    else {
        console.log('you have passed an empty section - delete instead')
    }
}

export default AddJournalSection;