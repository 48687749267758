import { apiUrl } from '../config.js';
import axios from 'axios';

function GetEmployers(callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    axios({
        method: 'get',
        url: apiUrl + 'getEmployers',
        headers: { 'authorization': token, 'Content-Type': 'application/json' },
    })
    .catch(function(error) {
        console.log(error);
        return 'fail';
    })
    .then(function(response) {
        if(response.statusText === 'OK') callback(response.data);
        else return 'fail'; 
    })
}

export default GetEmployers;