import axios from 'axios';
import { apiUrl } from '../config.js';

function DeleteJournalEntry(payload, callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
    axios({
        method: 'post',
        url: apiUrl + 'deleteJournal',
        headers: { 'authorization': token, 'Content-Type': 'application/json' },
        data: {
            journalId: payload.id,
        }
    })
    .catch(function(error) {
        console.log(error);
        callback(error);
    })
    .then(function(response) {
        callback(response);
    })
}

export default DeleteJournalEntry;