import React from 'react';
import Modal from 'react-modal';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');


function CurrentStatus(payload) {
    const currentLocation = payload.currentStatusArray[0].City + ', ' + payload.currentStatusArray[0].State;
    const jobSearchStatus = payload.currentStatusArray[0].JobSearchStatus + '(' + payload.currentStatusArray[0].JobSearchStatusDetails + ')';
    const lastUpdateDate = payload.currentStatusArray[0].LastUpdate;
    const remoteHybridOffice = payload.currentStatusArray[0].Remote ? 'Remote' : 'Hybrid';

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
 
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#e2e2e2';
    }
  
    function closeModal() {
      setIsOpen(false);
    }


    return (      
      <div className='app-resume-header-modal-button-container'>
        <button className='app-resume-modal-open-button' onClick={openModal}>View Job Search Status</button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 className='app-resume-bio-header' ref={(_subtitle) => (subtitle = _subtitle)}>Current Job Search status</h2>
          <button className='app-modal-close-button' onClick={closeModal}>close</button>
          <div className='app-modal-content'>
            <div className='app-modal-content-current-status-container'>
                <div className='app-modal-content-current-status-label'><span className='app-modal-content-current-status-label-text'>Job Search Status: </span><span className='app-modal-content-current-status-value'>{jobSearchStatus}</span></div>
                <div className='app-modal-content-current-status-label'><span className='app-modal-content-current-status-label-text'>Remote/Hybrid/On Site: </span><span className='app-modal-content-current-status-value'>{remoteHybridOffice}</span></div>
                <div className='app-modal-content-current-status-label'><span className='app-modal-content-current-status-label-text'>Location:</span> <span className='app-modal-content-current-status-value'>{currentLocation}</span></div>
                <div className='app-modal-content-current-status-label'><span className='app-modal-content-current-status-label-text'>Last Updated: </span><span className='app-modal-content-current-status-value'>{lastUpdateDate}</span></div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }


export default CurrentStatus;