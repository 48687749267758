import { apiUrl } from '../config.js';
import axios from 'axios';

function GetJournal(callback) {
    let auth = JSON.parse(window.sessionStorage.getItem('user'));
    let token = auth.token;
        axios({
        method: 'get',
        url: apiUrl + 'getJournal',
        headers: { 'authorization': token, 'Content-Type': 'application/json' },
    })
    .catch(function(error) {
        console.log(error);
        callback(error);
    })
    .then(function(response) {
        //this one we want to format a bit differently than pure db output
        let journalIds = [];
        let results = [];
        let data = response.data[0];
        for(let i=0; i < data.length; i++) {
            //first time through - we don't have the parent journal yet
            if(journalIds.indexOf(data[i].JournalId) === -1) {
                journalIds.push(data[i].JournalId);

                let thisJournal = {
                    journalId: data[i].JournalId,
                    journalName: data[i].CreateDate,
                    sections: []
                };

                thisJournal.sections.push({sectionId: data[i].SectionId, sectionText: data[i].SectionText, displayOrder: data[i].DisplayOrder});
                
                results.push(thisJournal);
            } else {
                //we have the parent journal - just add the section
                for(let j=0; j < results.length; j++) {
                    if(results[j].journalId === data[i].JournalId) {
                        results[j].sections.push({sectionId: data[i].SectionId, sectionText: data[i].SectionText, displayOrder: data[i].DisplayOrder});
                    }
                }
            }
        };
        callback(results);
    })
}

export default GetJournal;