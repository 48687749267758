import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ConsoleView from './controls/consoleView';
import ResumeView from './controls/resumeView';
import AdminView from './controls/adminView';
import JournalView from './controls/journalView.js';
import VrView from './controls/vrView.js';

import './App.css';

function App() {
  return (
    <div className="app" id="rootElement">
      <BrowserRouter>
        <Routes>
          <Route path="resume" element={<ResumeView  />} />
          <Route path="journal" element={<JournalView />} />
          <Route path="admin" element={<AdminView />} />
          <Route path="vr" element={<VrView />} />
          <Route path="*" element={<ConsoleView  />} />
        </Routes>
      </BrowserRouter>
    </div>
    )
}

export default App;